export class ComponentBase {
  get vm() {
    return this._vm;
  }

  get model() {
    return this.vm.model;
  }

  set model(value) {
    this.vm.model = value;
  }

  constructor(_vm) {
    this._vm = _vm;
  }

}
