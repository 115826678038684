function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { InjectionFactoryException } from "../InjectionFactoryException";
export class InjectionScopeException extends InjectionFactoryException {
  get scope() {
    return this._scope;
  }

  constructor(_scope, message, messageTemplateValues = {}, innerException) {
    super(message, _objectSpread(_objectSpread({}, messageTemplateValues), {}, {
      scope: _scope
    }), innerException);
    this._scope = _scope;
    InjectionScopeException.setPrototype(this);
  }

  toString() {
    let toString = super.toString();
    if (this._scope && toString.indexOf(this._scope.toString()) == -1) toString += `\nInjection Scope: ${this._scope}`;
    return toString;
  }

}
