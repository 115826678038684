import { ObjectBase } from '@awesome-nodes/object';
import { MulticastDelegate } from '@awesome-nodes/object/model';
export class ServiceBase extends ObjectBase {
  constructor(...args) {
    super(...args);
    this._backgroundWorkers = new Map();
  }

  get backgroundWorkers() {
    return this._backgroundWorkers;
  }

  get isBusy() {
    return !!this._backgroundWorkers.size;
  }

  createBackgroundWorker(observable, workerName) {
    const backgroundWorker = MulticastDelegate.multicast(observable);
    backgroundWorker.subscribe({
      complete: () => this._backgroundWorkers.delete(workerName),
      error: () => this._backgroundWorkers.delete(workerName)
    });

    this._backgroundWorkers.set(workerName, backgroundWorker);

    return backgroundWorker;
  }

}
