import InjectionFactory from "./";
import { InjectionToken } from "./injection/InjectionToken";
import 'reflect-metadata';
export function Provider(metadata) {
  return constructor => {
    InjectionFactory.registerProvider(InjectionToken.normalize(metadata.token), constructor, metadata.scope);
    return constructor;
  };
}
const PROVIDER_METADATA_KEY = Symbol('PROVIDER_KEY');
const PROVIDER_METADATA_PROPERTY_KEY = 'PROVIDER_METADATA';
export function getProviderMetadata(target) {
  return Reflect.getMetadata(PROVIDER_METADATA_KEY, target, PROVIDER_METADATA_PROPERTY_KEY);
}
export function setProviderMetadata(target, token) {
  Reflect.defineMetadata(PROVIDER_METADATA_KEY, {
    token
  }, target, PROVIDER_METADATA_PROPERTY_KEY);
}
