import { isPrototypeOf } from '@awesome-nodes/object';
import InjectionFactory from "./";
import { InjectableDependencyNotFoundException } from "./InjectableDependencyNotFoundException";
import { InjectionProvider } from "./injection/InjectionProvider";
import { isProviderConfig } from "./injection/InjectionProviderConfig";
import { InjectionScope } from "./injection/InjectionScope";
import { InjectionToken } from "./injection/InjectionToken";
import { UnknownInjectionTokenScopeException } from "./injection/InjectionTokenException";
import { getProviderMetadata } from "./Provider.decorator";
import { ClassProvider } from "./providers/ClassProvider";
import { convertFromProviderConfig } from "./providers/ProviderConfig";
export const INJECTABLE_METADATA_KEY = Symbol('INJECTABLE_KEY');
export const INJECTABLE_METADATA_PROPERTY_KEY = 'INJECTABLE_METADATA';
export function getInjectableMetadata(target) {
  return Reflect.getMetadata(INJECTABLE_METADATA_KEY, target, INJECTABLE_METADATA_PROPERTY_KEY);
}
export function setInjectableMetadata(target, data) {
  Reflect.defineMetadata(INJECTABLE_METADATA_KEY, data, target, INJECTABLE_METADATA_PROPERTY_KEY);
}
export function Injectable(metadata) {
  return constructor => {
    const token = metadata && metadata.token ? metadata.token : constructor.name;
    const construct = metadata && metadata.construct ? metadata.construct : undefined;
    const scope = metadata && metadata.scope ? metadata.scope : InjectionFactory.mainScope;
    const injectionScope = scope instanceof InjectionScope ? scope : InjectionFactory.locateScope(scope);
    const providers = metadata ? metadata.providers : undefined;
    let newConstructor = constructor;
    if (!injectionScope) throw new UnknownInjectionTokenScopeException(scope, token, 'Dependency inject failed. ');
    InjectionFactory.registerToken(token, scope);
    providers && providers.forEach(_provider => {
      let providerType;

      if (!isPrototypeOf(_provider, InjectionProvider)) {
        let providerToken;
        let providerInstance;
        let providerScope;

        if (isProviderConfig(_provider)) {
          providerScope = injectionScope.addSubscope(InjectionToken.toString(token));
          providerInstance = convertFromProviderConfig(_provider, providerScope);
          providerType = providerInstance.constructor;
          providerToken = injectionScope.locateToken(providerInstance.token);
          if (!providerToken) throw new InjectableDependencyNotFoundException(injectionScope, providerType, token);
        } else {
          providerScope = injectionScope.addSubscope(InjectionToken.toString(token));
          providerType = _provider;
          const providerMetadata = getProviderMetadata(providerType);
          providerToken = injectionScope.locateToken(providerMetadata ? providerMetadata.token : providerType.name);
          if (!providerToken) throw new InjectableDependencyNotFoundException(injectionScope, providerType, token);
          if (providerType == constructor) providerType = newConstructor = CreateConstructor(providerType);
          providerInstance = new ClassProvider(providerScope, providerToken, providerType);
        }

        providerScope.addProvider(InjectionToken.normalize(providerInstance.token), providerInstance);
      }
    });
    setInjectableMetadata(constructor, metadata);

    function CreateConstructor(constructor) {
      if (construct) {
        if (newConstructor == constructor) {
          return class extends constructor {
            constructor(...args) {
              super(...args);
              construct && construct(this, ...args);
            }

          };
        } else return newConstructor;
      }

      return constructor;
    }

    return CreateConstructor(constructor);
  };
}
