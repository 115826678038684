function _classPrivateFieldLooseBase(receiver, privateKey) { if (!Object.prototype.hasOwnProperty.call(receiver, privateKey)) { throw new TypeError("attempted to use private field on non-instance"); } return receiver; }

var id = 0;

function _classPrivateFieldLooseKey(name) { return "__private_" + id++ + "_" + name; }

import { EventArgs } from '@awesome-nodes/object/model';
import { EventDelegate } from "./EventDelegate";
import { ObjectModel } from "./ObjectModel";

var _isDisposed = _classPrivateFieldLooseKey("_isDisposed");

var _disposing = _classPrivateFieldLooseKey("_disposing");

var _disposed = _classPrivateFieldLooseKey("_disposed");

export class Disposable extends ObjectModel {
  constructor(...args) {
    super(...args);
    Object.defineProperty(this, _isDisposed, {
      writable: true,
      value: false
    });
    Object.defineProperty(this, _disposing, {
      writable: true,
      value: new EventDelegate(this)
    });
    Object.defineProperty(this, _disposed, {
      writable: true,
      value: new EventDelegate(this)
    });
  }

  onDisposing(args) {
    _classPrivateFieldLooseBase(this, _disposing)[_disposing].invoke(args);
  }

  onDisposed(args) {
    _classPrivateFieldLooseBase(this, _disposed)[_disposed].invoke(args);
  }

  dispose(disposing) {
    if (disposing === undefined && !_classPrivateFieldLooseBase(this, _isDisposed)[_isDisposed]) {
      this.onDisposing(EventArgs.empty);
      this.dispose(true);
      this.onDisposed(EventArgs.empty);
      return;
    }

    _classPrivateFieldLooseBase(this, _isDisposed)[_isDisposed] = true;
  }

  get disposing() {
    return _classPrivateFieldLooseBase(this, _disposing)[_disposing];
  }

  get disposed() {
    return _classPrivateFieldLooseBase(this, _disposed)[_disposed];
  }

  get isDisposed() {
    return _classPrivateFieldLooseBase(this, _isDisposed)[_isDisposed];
  }

}
