import { Enum } from "./Enum";
export class FlagsEnum extends Enum {
  get flags() {
    return this._flags;
  }

  constructor(value, name) {
    super(value, name);
    this._flags = new Array();
    const thisType = this.constructor;

    const _value = thisType.parse(value);

    if (_value != null) return;

    for (const _property in thisType) {
      if (!thisType.hasOwnProperty(_property)) continue;
      const enumMember = thisType[_property];
      if (!(enumMember instanceof thisType)) continue;
      if ((value & enumMember.value) == enumMember.value && !this._flags.some(f => f == enumMember)) this._flags[_property] = enumMember;
    }
  }

  hasFlag(flag) {
    return this._flags.some(f => f == flag);
  }

  toString() {
    return this.flags.length > 0 ? `${this.name}(${this._flags.filter(item => item != null).map(item => item.value).join('|')})` : super.toString();
  }

}
