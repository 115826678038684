import { EventArgs, Exception, ObjectBase } from "./..";
import { Observable } from 'rxjs';
export * from "../EventArgs";
export class Delegate extends ObjectBase {
  get target() {
    return this._target;
  }

  get handler() {
    return this._handler;
  }

  get preventSubscribe() {
    return this._preventSubscribe;
  }

  set preventSubscribe(value) {
    this._preventSubscribe = value;
  }

  constructor(_target) {
    super();
    this._target = _target;
    this._handler = void 0;
    this._preventSubscribe = false;
    this._observable = void 0;
    this._subscription = void 0;
    this._observer = void 0;
  }

  subscribe(handler, thisArg) {
    if (typeof handler !== 'function') throw new Exception(`WebCore: A event subscription requires a handler function. Provided Value:${handler}`);
    if (this._preventSubscribe && this._handler) throw new Exception(`WebCore: The handler '${handler.prototype.constructor.name}' cannot be registered. Only one event subscriber can be registered at a time.`);
    if (this._subscription != null) this._subscription.unsubscribe();
    if (thisArg != null) handler = handler.bind(thisArg);
    this._handler = handler;
    this._observable = new Observable(observer => {
      this._observer = observer;
      return () => {
        this._handler = undefined;
        this._observable = undefined;
        this._subscription = undefined;
        this._observer = undefined;
      };
    });
    return this._subscription = this._observable.subscribe(eventArgs => {
      this._handler && this._handler(this._target, eventArgs ? eventArgs : EventArgs.empty);
    });
  }

  unsubscribe(handler) {
    if (this._handler == handler && this._subscription != null) this._subscription.unsubscribe();
  }

  invoke(eventArgs) {
    if (this._observer) this._observer.next(eventArgs ? eventArgs : EventArgs.empty);
  }

  async invokeAsync(eventArgs) {
    return new Promise(resolve => {
      resolve(eventArgs);
    }).then(() => {
      this.invoke(eventArgs);
      return eventArgs;
    });
  }

}
