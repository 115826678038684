/** edit all companies */
export const ROLE_ALL_COMP_E = "ROLE_ALL_COMP_E";

/** edit company users */
export const ROLE_COMP_USR_E = "ROLE_COMP_USR_E";

/** edit all tx sources */
export const ROLE_ALL_SRC_E = "ROLE_ALL_SRC_E";

/** read all transactions */
export const ROLE_ALL_TX_R = "ROLE_ALL_TX_R";