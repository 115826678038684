import { ArgumentNullException, isConstructor, isString, ObjectBase } from '@awesome-nodes/object';
export class InjectionToken extends ObjectBase {
  get key() {
    return this._key;
  }

  get scope() {
    return this._scope;
  }

  get value() {
    return this._scope.inject(this._key);
  }

  constructor(_key, _scope) {
    super(String(_key));
    this._key = _key;
    this._scope = _scope;
  }

  toString() {
    return String(this._key);
  }

  static normalize(token) {
    if (!token) throw new ArgumentNullException('Normalizable injection tokens can not be empty. ', 'token');
    return isString(token) || token instanceof InjectionToken || typeof token != 'function' || !isConstructor(token) ? token : token.name;
  }

  static simplify(token) {
    token = this.normalize(token);
    return token instanceof InjectionToken ? token._key : token;
  }

  static compare(token1, token2) {
    return this.simplify(token1) == this.simplify(token2);
  }

  static toString(token) {
    return String(InjectionToken.simplify(token));
  }

}
