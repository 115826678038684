import { __classPrivateFieldGet, __classPrivateFieldSet } from 'tslib';
import * as i1 from '@yukawa/chain-base-angular-client';
import { EventArgs, Exception, ActionObserver, ServiceBase, EventDelegate, ObjectModel, ArgumentException, TemplateString, ServiceException, ServiceProviderBase, ConfigService } from '@yukawa/chain-base-angular-client';
import { lastValueFrom, throwError, tap, firstValueFrom, map, of, switchMap, Observable, catchError } from 'rxjs';
import { Enum, FlagsEnum, TemplateString as TemplateString$1 } from '@awesome-nodes/object';
import { ObjectBase } from '@awesome-nodes/object/data';
import * as i1$1 from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { map as map$1 } from 'rxjs/operators';
class SessionChangedEventArgs extends EventArgs {
  constructor(_session, _options) {
    super();
    this._session = _session;
    this._options = _options;
  }
  get session() {
    return this._session;
  }
  get options() {
    return this._options;
  }
}
class SessionException extends Exception {}
var _a, _Session__instance;
class Session extends ActionObserver {
  //region Instance Members
  constructor(_user) {
    super();
    this._user = _user;
    this.onComplete = this.onSessionChanged;
    this.onError = ServiceBase.handleError;
  }
  //region Static Properties
  static get sessionChanged() {
    return this._sessionChanged;
  }
  static get instance() {
    return __classPrivateFieldGet(Session, _a, "f", _Session__instance);
  }
  //endregion
  get user() {
    return this._user;
  }
  //region Static Members
  static async restore(sessionService) {
    let session = null;
    const {
      auth,
      store
    } = sessionService;
    try {
      const newSession = await lastValueFrom(auth.restore(store.getJSON('token')));
      if (newSession instanceof Session.SessionConnected) {
        sessionService.store.setJSON('token', newSession.user.authToken);
        sessionService.store.setJSON('user', newSession.user.info);
      }
      session = newSession;
    } catch (error) {
      console.error(error);
    }
    if (session == null) {
      session = new this.SessionDisconnected();
    }
    session.onSessionChanged(session, new SessionChangedEventArgs(session));
    return __classPrivateFieldGet(this, _a, "f", _Session__instance) instanceof Session.SessionConnected;
  }
  static refresh(sessionService) {
    if (Session.instance instanceof Session.SessionDisconnected) {
      return throwError(() => new SessionException('Session disconnected'));
    }
    return sessionService.auth.restore(Session.instance.user?.authToken.toJSON()).pipe(tap(session => {
      if (session instanceof Session.SessionConnected) {
        sessionService.store.setJSON('token', session.user.authToken);
        sessionService.store.setJSON('user', session.user.info);
      }
      return __classPrivateFieldSet(Session, _a, session, "f", _Session__instance);
    }));
  }
  //endregion
  onSessionChanged(sender, ea) {
    __classPrivateFieldSet(Session, _a, ea.session, "f", _Session__instance);
    Session._sessionChanged.invoke(ea);
  }
}
_a = Session;
//eslint-disable-next-line @typescript-eslint/naming-convention
Session.SessionDisconnected = class extends Session {
  constructor() {
    super();
  }
  async login(sessionService, credentials) {
    return await firstValueFrom(this.observe(() => sessionService.auth.login(credentials).pipe(map(user => new Session.SessionConnected(user))), {
      complete: () => {
        const session = this.actionResultValue;
        const user = session.user;
        try {
          sessionService.store.setJSON('token', user.authToken);
          sessionService.store.setJSON('user', user.info);
        } catch (error) {
          console.error(error);
        }
        return new SessionChangedEventArgs(session);
      }
    }));
  }
  logout() {
    throw new SessionException('Logout failed. No session active!');
  }
};
//eslint-disable-next-line @typescript-eslint/naming-convention
Session.SessionConnected = class extends Session {
  constructor(user) {
    super(user);
  }
  get user() {
    return this._user;
  }
  login() {
    throw new SessionException('Login failed. Session is not disconnected.');
  }
  logout(sessionService, options) {
    sessionService.auth.logout();
    this._user = null;
    sessionService.store.clear();
    this.onSessionChanged(this, new SessionChangedEventArgs(new Session.SessionDisconnected(), options));
  }
};
_Session__instance = {
  value: void 0
};
Session._sessionChanged = new EventDelegate(null);
class WebTokenException extends Exception {}
class WebToken extends ObjectModel {
  constructor(_token) {
    super();
    this._token = _token;
    if (!/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(_token)) {
      throw new WebTokenException(`The format of the provided jwt web token is invalid. WebToken: ${_token}`);
    }
    const parts = _token.split('.');
    try {
      this._header = JSON.parse(atob(parts[0]));
      this._payload = JSON.parse(atob(parts[1]));
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw new WebTokenException('Invalid web token content. Probably no base64 encoded json payload provided.', undefined, error);
      }
      throw error;
    }
    this._signature = parts[2];
  }
  //region Public Properties
  get header() {
    return this._header;
  }
  get payload() {
    return this._payload;
  }
  get signature() {
    return this._signature;
  }
  get subject() {
    return this._payload.sub;
  }
  get issuedAt() {
    return new Date(this._payload.iat * 1000);
  }
  get expirationTime() {
    return new Date(this._payload.exp * 1000);
  }
  get isExpired() {
    return new Date().getTime() > this.expirationTime.getTime();
  }
  //endregion
  toString() {
    return this._token;
  }
}
class ChangePassword extends ObjectModel {
  constructor(changePassword) {
    super();
    this.fqn = changePassword.fqn;
    this.key = changePassword.key;
    this.messages = changePassword.messages;
    this.op = changePassword.op;
    this.stamp = changePassword.stamp;
    this.took = changePassword.took;
    this.userId = changePassword.userId;
  }
}
class Role extends Enum {}
Role.none = Role.enum(0x0, 'None');
Role.admin = Role.enum(0x2, 'ROLE_ADMIN');
Role.developer = Role.enum(0x4, 'ROLE_DEVELOPER');
Role.tester = Role.enum(0x8, 'ROLE_TESTER');
Role.user = Role.enum(0x10, 'ROLE_USER');
Role.auth = Role.enum(0x20, 'ROLE_AUTH');
Role.info = Role.enum(0x40, 'ROLE_INFO');
Role.module = Role.enum(0x80, 'ROLE_MODULE');
Role.man = Role.enum(0x100, 'ROLE_MAN');
Role.profileReader = Role.enum(0x200, 'ROLE_PROF_R');
Role.contentEdit = Role.enum(0x400, 'ROLE_CONT_E');
Role.interestEdit = Role.enum(0x800, 'ROLE_INT_E');
Role.switchEnv = Role.enum(0x1000, 'ROLE_SWITCH_ENV');

/* eslint-disable no-bitwise */
class Roles extends FlagsEnum {}
Roles.none = Roles.enum(Role.none.value, Role.none.name);
Roles.user = Roles.enum(Role.user.value, Role.user.name);
Roles.tester = Roles.enum(Role.tester.value ^ Role.user.value, Role.tester.name);
Roles.developer = Roles.enum(Role.developer.value ^ Role.tester.value ^ Role.user.value, Role.developer.name);
Roles.admin = Roles.enum(Role.admin.value ^ Role.developer.value ^ Role.tester.value ^ Role.user.value, Role.admin.name);
Roles.yukawa = Roles.enum(0x20, 'Yukawa');
Roles.yukawaAdmin = Roles.enum(Roles.yukawa.value ^ Roles.admin.value, Roles.yukawa.name + Roles.admin.name);
Roles.yukawaDeveloper = Roles.enum(Roles.yukawa.value ^ Roles.developer.value, Roles.yukawa.name + Roles.developer.name);
Roles.yukawaTester = Roles.enum(Roles.yukawa.value ^ Roles.tester.value, Roles.yukawa.name + Roles.tester.name);
Roles.yukawaUser = Roles.enum(Roles.yukawa.value ^ Roles.user.value, Roles.yukawa.name + Roles.user.name);
class UnknownUserRoleException extends ArgumentException {
  constructor(role, paramName, innerException) {
    super(TemplateString`Unknown user role '${'role'}'.`({
      role
    }), paramName, innerException);
  }
}
class User extends ObjectModel {
  constructor(_sessionToken, _userInfo) {
    super(_userInfo.username);
    this._sessionToken = _sessionToken;
    this._userInfo = _userInfo;
    let role = Roles.none;
    const userRoles = _sessionToken.accessToken.payload.scope.map(_role => Role.parse(_role) || _role);
    if (!userRoles.some(_role => typeof _role !== 'string' && User.roles.includes(_role))) {
      userRoles.push(Role.user);
    }
    if (userRoles.includes(Role.admin)) {
      role = Roles.admin;
    } else if (userRoles.includes(Role.developer)) {
      role = Roles.developer;
    } else if (userRoles.includes(Role.tester)) {
      role = Roles.tester;
    } else if (userRoles.includes(Role.user)) {
      role = Roles.user;
    }
    let tenantType;
    switch (this._sessionToken.accessToken.payload.details.orgId) {
      case 'yuk':
        tenantType = Roles.yukawa;
        break;
      default:
        tenantType = Roles.none;
        break;
    }
    //eslint-disable-next-line no-bitwise
    role = Roles.parse(role.value ^ tenantType.value);
    // Note: On implementations failure: check enumeration flags
    if (role == null) {
      throw new UnknownUserRoleException(this._sessionToken.accessToken.payload.details.orgId, '_sessionToken.accessToken.payload.details.orgId');
    }
    if (role === Roles.none) {
      throw new ArgumentException('User role could not be determined.', '_sessionToken.accessToken.payload.scope');
    }
    this._role = role;
  }
  get authToken() {
    return this._sessionToken;
  }
  get info() {
    return this._userInfo;
  }
  get role() {
    return this._role;
  }
  //region Role Types
  get isAdmin() {
    return this._role.hasFlag(Roles.admin);
  }
  get isDeveloper() {
    return this._role.hasFlag(Roles.developer);
  }
  get isTester() {
    return this._role.hasFlag(Roles.tester);
  }
  get isUser() {
    return this._role.hasFlag(Roles.user);
  }
  get isYukawa() {
    return this._role.hasFlag(Roles.yukawa);
  }
  hasRole(role) {
    return this._sessionToken.scope.includes(role.name);
  }
}
User.roles = new Array(Role.admin, Role.developer, Role.tester, Role.user);
class AccountStatus extends ObjectBase {
  constructor(accountStatus) {
    super();
    this._accountNonExpired = accountStatus.accountNonExpired ?? false;
    this._accountNonLocked = accountStatus.accountNonLocked ?? false;
    this._credentialsNonExpired = accountStatus.credentialsNonExpired ?? false;
    this._enabled = accountStatus.enabled ?? false;
  }
  //region Public Properties
  get accountNonExpired() {
    return this._accountNonExpired;
  }
  set accountNonExpired(value) {
    this._accountNonExpired = value;
  }
  get accountNonLocked() {
    return this._accountNonLocked;
  }
  set accountNonLocked(value) {
    this._accountNonLocked = value;
  }
  get credentialsNonExpired() {
    return this._credentialsNonExpired;
  }
  set credentialsNonExpired(value) {
    this._credentialsNonExpired = value;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(value) {
    this._enabled = value;
  }
  //endregion
  toJSON() {
    return super.toJSON('accountNonExpired', 'accountNonLocked', 'credentialsNonExpired', 'enabled');
  }
}
var _Credentials__orgID, _Credentials__password, _Credentials__username;
class Credentials extends ObjectBase {
  constructor(username, password, orgId) {
    super();
    _Credentials__orgID.set(this, void 0);
    _Credentials__password.set(this, void 0);
    _Credentials__username.set(this, void 0);
    if (typeof username !== 'object') {
      __classPrivateFieldSet(this, _Credentials__orgID, orgId, "f");
      __classPrivateFieldSet(this, _Credentials__password, password, "f");
      __classPrivateFieldSet(this, _Credentials__username, username, "f");
    } else {
      __classPrivateFieldSet(this, _Credentials__orgID, username.orgId, "f");
      __classPrivateFieldSet(this, _Credentials__password, username.password, "f");
      __classPrivateFieldSet(this, _Credentials__username, username.username, "f");
    }
  }
  //region Public Properties
  get orgId() {
    return __classPrivateFieldGet(this, _Credentials__orgID, "f");
  }
  set orgId(value) {
    __classPrivateFieldSet(this, _Credentials__orgID, value, "f");
  }
  get username() {
    return __classPrivateFieldGet(this, _Credentials__username, "f");
  }
  set username(value) {
    __classPrivateFieldSet(this, _Credentials__username, value, "f");
  }
  get password() {
    return __classPrivateFieldGet(this, _Credentials__password, "f");
  }
  set password(value) {
    __classPrivateFieldSet(this, _Credentials__password, value, "f");
  }
  //endregion
  equals(other) {
    return super.equals(other) && __classPrivateFieldGet(this, _Credentials__orgID, "f") === __classPrivateFieldGet(other, _Credentials__orgID, "f") && __classPrivateFieldGet(this, _Credentials__username, "f") === __classPrivateFieldGet(other, _Credentials__username, "f") && __classPrivateFieldGet(this, _Credentials__password, "f") === __classPrivateFieldGet(other, _Credentials__password, "f");
  }
  toJSON() {
    return super.toJSON('username', 'password', 'orgId');
  }
}
_Credentials__orgID = new WeakMap(), _Credentials__password = new WeakMap(), _Credentials__username = new WeakMap();
class Account extends ObjectBase {
  constructor(account) {
    super();
    this._credentials = new Credentials(account.credentials);
    this._details = account.details ?? {};
    this._roleContexts = account.roleContexts ?? [];
    this._status = new AccountStatus(account.status);
  }
  //region Public Properties
  get credentials() {
    return this._credentials;
  }
  set credentials(value) {
    this._credentials = value;
  }
  get details() {
    return this._details;
  }
  set details(value) {
    this._details = value;
  }
  get roleContexts() {
    return this._roleContexts;
  }
  set roleContexts(value) {
    this._roleContexts = value;
  }
  get status() {
    return this._status;
  }
  set status(value) {
    this._status = value;
  }
  //endregion
  toJSON() {
    return super.toJSON('credentials', 'details', 'roleContexts', 'status');
  }
}
class Address extends ObjectBase {
  constructor(address) {
    super();
    this._addressId = address.addressId;
    this._city = address.city;
    this._countryCode = address.countryCode;
    this._geoLocation = address.geoLocation;
    this._houseNumber = address.houseNumber;
    this._region = address.region;
    this._state = address.state;
    this._street = address.street;
    this._type = address.type;
    this._zipCode = address.zipCode;
  }
  //region Public Properties
  get addressId() {
    return this._addressId;
  }
  set addressId(value) {
    this._addressId = value;
  }
  get city() {
    return this._city;
  }
  set city(value) {
    this._city = value;
  }
  get countryCode() {
    return this._countryCode;
  }
  set countryCode(value) {
    this._countryCode = value;
  }
  get geoLocation() {
    return this._geoLocation;
  }
  set geoLocation(value) {
    this._geoLocation = value;
  }
  get houseNumber() {
    return this._houseNumber;
  }
  set houseNumber(value) {
    this._houseNumber = value;
  }
  get region() {
    return this._region;
  }
  set region(value) {
    this._region = value;
  }
  get state() {
    return this._state;
  }
  set state(value) {
    this._state = value;
  }
  get street() {
    return this._street;
  }
  set street(value) {
    this._street = value;
  }
  get type() {
    return this._type;
  }
  set type(value) {
    this._type = value;
  }
  get zipCode() {
    return this._zipCode;
  }
  set zipCode(value) {
    this._zipCode = value;
  }
  //endregion
  toJSON() {
    return super.toJSON('addressId', 'city', 'countryCode', 'geoLocation', 'houseNumber', 'region', 'state', 'street', 'type', 'zipCode');
  }
}
class Person extends ObjectBase {
  constructor(person) {
    super();
    this._addresses = person.addresses ? person.addresses.map(a => new Address(a)) : [];
    this._change = person.change;
    this._companyName = person.companyName;
    this._created = person.created;
    this._email = person.email;
    this._firstName = person.firstName;
    this._lang = person.lang;
    this._lastName = person.lastName;
    this._mobile = person.mobile;
    this._phoneNumber = person.phoneNumber;
    this._role = person.role;
    this._shortName = person.shortName;
    this._title = person.title;
    this._vatNumber = person.vatNumber;
  }
  //region Public Properties
  get addresses() {
    return this._addresses;
  }
  set addresses(value) {
    this._addresses = value;
  }
  get change() {
    return this._change;
  }
  set change(value) {
    this._change = value;
  }
  get companyName() {
    return this._companyName;
  }
  set companyName(value) {
    this._companyName = value;
  }
  get created() {
    return this._created;
  }
  set created(value) {
    this._created = value;
  }
  get email() {
    return this._email;
  }
  set email(value) {
    this._email = value;
  }
  get firstName() {
    return this._firstName;
  }
  set firstName(value) {
    this._firstName = value;
  }
  get lang() {
    return this._lang;
  }
  set lang(value) {
    this._lang = value;
  }
  get lastName() {
    return this._lastName;
  }
  set lastName(value) {
    this._lastName = value;
  }
  get mobile() {
    return this._mobile;
  }
  set mobile(value) {
    this._mobile = value;
  }
  get phoneNumber() {
    return this._phoneNumber;
  }
  set phoneNumber(value) {
    this._phoneNumber = value;
  }
  get role() {
    return this._role;
  }
  set role(value) {
    this._role = value;
  }
  get shortName() {
    return this._shortName;
  }
  set shortName(value) {
    this._shortName = value;
  }
  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
  }
  get vatNumber() {
    return this._vatNumber;
  }
  set vatNumber(value) {
    this._vatNumber = value;
  }
  //endregion
  toJSON() {
    return super.toJSON('addresses', 'change', 'companyName', 'created', 'email', 'firstName', 'lang', 'lastName', 'mobile', 'phoneNumber', 'role', 'shortName', 'title', 'vatNumber');
  }
}
class UserInfo extends ObjectBase {
  constructor(user) {
    super(user.username);
    this._account = !user.account ? undefined : new Account(user.account);
    this._change = user.change;
    this._created = user.created;
    this._defaultOrgId = user.defaultOrgId;
    this._details = user.details ?? {};
    this._groupContexts = user.groupContexts ?? [];
    this._orgIds = user.orgIds || [];
    this._owner = user.owner;
    this._person = new Person(user.person);
    this._userId = user.userId;
    this._username = user.username;
  }
  //region Public Properties
  get account() {
    return this._account;
  }
  set account(value) {
    this._account = value;
  }
  get change() {
    return this._change;
  }
  set change(value) {
    this._change = value;
  }
  get created() {
    return this._created;
  }
  set created(value) {
    this._created = value;
  }
  get defaultOrgId() {
    return this._defaultOrgId;
  }
  set defaultOrgId(value) {
    this._defaultOrgId = value;
  }
  get details() {
    return this._details;
  }
  set details(value) {
    this._details = value;
  }
  get groupContexts() {
    return this._groupContexts;
  }
  set groupContexts(value) {
    this._groupContexts = value;
  }
  get groups() {
    return this._groups;
  }
  set groups(value) {
    this._groups = value;
  }
  get orgIds() {
    return this._orgIds;
  }
  set orgIds(value) {
    this._orgIds = value;
  }
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
  }
  get person() {
    return this._person;
  }
  set person(value) {
    this._person = value;
  }
  get userId() {
    return this._userId;
  }
  set userId(value) {
    this._userId = value;
  }
  get username() {
    return this._username;
  }
  set username(value) {
    this._username = value;
  }
  //endregion
  equals(other) {
    return super.equals(other) && this._username === other._username;
  }
  toJSON() {
    return super.toJSON('account', 'change', 'created', 'defaultOrgId', 'details', 'groupContexts', 'groups', 'orgIds', 'owner', 'person', 'userId', 'username');
  }
}
UserInfo.empty = new UserInfo({
  account: {
    credentials: {},
    status: {}
  },
  person: {}
});
class DateUtils {
  /**
   * Some browsers cannot handle Dateformat dd-mm-yyyy. This function converts to
   * dd/mm/yyyy (Safari requires this format for some reason) and fixes timezone offset
   *
   * @param date Date string that should be parsed
   */
  static parseDateString(date) {
    let formattedDate = date.replace(/-/g, '/').toString();
    formattedDate = formattedDate.replace(/T/g, ' ');
    formattedDate = formattedDate.split('.')[0];
    const newDate = new Date(formattedDate);
    return new Date(newDate.getTime() - new Date().getTimezoneOffset() * 60000);
  }
}
class AuthToken extends ObjectBase {
  constructor(authTokenResponse) {
    super();
    this._access_expires = authTokenResponse.access_expires;
    this._access_token = authTokenResponse.access_token;
    this._details = authTokenResponse.details;
    this._expires_in = authTokenResponse.expires_in;
    this._refresh_expires = authTokenResponse.refresh_expires;
    this._refresh_token = authTokenResponse.refresh_token;
    this._scope = authTokenResponse.scope;
    this._token_type = authTokenResponse.token_type;
    this._username = authTokenResponse.username;
    this._accessToken = new WebToken(this._access_token);
    this._refreshToken = new WebToken(this._refresh_token);
  }
  //region IAuthTokenResponse Members
  get access_expires() {
    return this._access_expires;
  }
  get access_token() {
    return this._access_token;
  }
  get details() {
    return this._details;
  }
  get expires_in() {
    return this._expires_in;
  }
  get refresh_expires() {
    return this._refresh_expires;
  }
  get refresh_token() {
    return this._refresh_token;
  }
  get scope() {
    return this._scope;
  }
  get token_type() {
    return this._token_type;
  }
  get username() {
    return this._username;
  }
  //endregion
  //region Public Properties
  get accessToken() {
    return this._accessToken;
  }
  get refreshToken() {
    return this._refreshToken;
  }
  get access_expired() {
    const now = new Date().getTime();
    const accessExpires = DateUtils.parseDateString(this._access_expires).getTime();
    return now > accessExpires;
  }
  get refresh_expired() {
    const now = new Date().getTime();
    const refreshExpires = DateUtils.parseDateString(this._refresh_expires).getTime();
    return now > refreshExpires;
  }
  //endregion
  toJSON() {
    return super.toJSON('access_expires', 'access_token', 'details', 'expires_in', 'refresh_expires', 'refresh_token', 'scope', 'token_type', 'username');
  }
}
class SessionStoreService {
  constructor(_configService) {
    this._configService = _configService;
  }
  get(item, defaultValue) {
    return localStorage.getItem(this.getKey(item)) ?? defaultValue;
  }
  set(item, value) {
    return localStorage.setItem(this.getKey(item), value);
  }
  getJSON(item) {
    const tokenJSON = this.get(item);
    return tokenJSON ? JSON.parse(tokenJSON) : null;
  }
  setJSON(item, value) {
    this.set(item, JSON.safeStringify(value, 0));
  }
  remove(item) {
    localStorage.removeItem(this.getKey(item));
  }
  clear() {
    this.remove('token');
    this.remove('user');
  }
  getKey(item) {
    return TemplateString`${'store'}=${'item'}`({
      store: this._configService.getValue('sessionStoreKey'),
      item: this._configService.getValue('sessionStorePrefix') + item
    });
  }
}
/** @nocollapse */
SessionStoreService.ɵfac = function SessionStoreService_Factory(t) {
  return new (t || SessionStoreService)(i0.ɵɵinject(i1.ConfigService));
};
/** @nocollapse */
SessionStoreService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SessionStoreService,
  factory: SessionStoreService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionStoreService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.ConfigService
    }];
  }, null);
})();
const authInterceptorOptions = {
  enabled: true,
  refreshEnabled: true
};
const AUTH_INTERCEPTOR_OPTIONS = authInterceptorOptions;
class AuthServiceBase extends ServiceBase {
  constructor(_userService, _configService, _httpClient) {
    super();
    this._userService = _userService;
    this._configService = _configService;
    this._httpClient = _httpClient;
    this._loginRequest = new EventDelegate(this);
  }
  get loginRequest() {
    return this._loginRequest;
  }
  get isAuthenticated() {
    return Session.instance instanceof Session.SessionConnected;
  }
  restore(sessionToken) {
    if (sessionToken == null) {
      return of(new Session.SessionDisconnected());
    }
    const authToken = sessionToken instanceof AuthToken ? sessionToken : new AuthToken(sessionToken);
    if (authToken.refresh_expired) {
      return of(new Session.SessionDisconnected());
    }
    let user$;
    if (authToken.access_expired) {
      user$ = this.createWorker('restore', this.refresh(sessionToken));
    } else if (!this.isAuthenticated) {
      user$ = this.createWorker('restore', of(sessionToken));
    } else {
      user$ = of(Session.instance?.user);
    }
    return user$.pipe(map(user => new Session.SessionConnected(user)));
  }
  logout() {
    return undefined;
  }
  doWork(workerName, observable) {
    switch (workerName) {
      case 'login':
      case 'restore':
        let authToken;
        return observable.pipe(map(response => authToken = new AuthToken(response)), switchMap(token => {
          AUTH_INTERCEPTOR_OPTIONS.refreshEnabled = false;
          const userInfoObservable = this._userService.loadUser(token);
          AUTH_INTERCEPTOR_OPTIONS.refreshEnabled = true;
          return userInfoObservable;
        }), map(userInfo => new User(authToken, userInfo)));
      default:
        throw new ServiceException(`Unknown service worker name: ${workerName}`);
    }
  }
}
var UserRestEndpoints;
(function (UserRestEndpoints) {
  UserRestEndpoints["createUser"] = "/";
  UserRestEndpoints["updateUser"] = "/";
  UserRestEndpoints["loadUser"] = "/";
  UserRestEndpoints["deleteUser"] = "/";
  UserRestEndpoints["synchronizeRoles"] = "/admin/synchronizeRoles";
  UserRestEndpoints["exportUsers"] = "/export";
  UserRestEndpoints["merge"] = "/merge";
  UserRestEndpoints["changePassword"] = "/password/change";
  UserRestEndpoints["resetPassword"] = "/password/reset";
  UserRestEndpoints["queryUsers"] = "/query";
  UserRestEndpoints["registerUser"] = "/register";
})(UserRestEndpoints || (UserRestEndpoints = {}));
class UserDesignService extends ServiceBase {
  constructor(_injector) {
    super();
    this._injector = _injector;
  }
  static generateUserInfo(sessionToken) {
    return new UserInfo({
      account: {
        credentials: {
          orgId: 'yuk',
          password: 'admin',
          username: sessionToken.username
        },
        details: {},
        roleContexts: [{
          orgId: 'string',
          roles: ['string']
        }],
        roles: ['string'],
        status: {
          accountNonExpired: true,
          accountNonLocked: true,
          credentialsNonExpired: true,
          enabled: true
        }
      },
      change: {
        date: new Date('2021-12-15T17:38:23.969Z'),
        notes: 'my example change',
        user: 'sample@yukawa.de'
      },
      created: {
        date: new Date('2021-12-15T17:38:23.969Z'),
        notes: 'my example change',
        user: 'sample@yukawa.de'
      },
      defaultOrgId: 'string',
      details: {},
      groupContexts: [{
        groups: ['string'],
        orgId: 'string'
      }],
      groups: ['string'],
      orgIds: ['string'],
      owner: {
        groups: ['string'],
        user: 'string'
      },
      person: {
        addresses: [{
          addressId: 'string',
          city: 'string',
          countryCode: 'string',
          geoLocation: {
            altitude: 0,
            latitude: 0,
            longitude: 0
          },
          houseNumber: 'string',
          region: 'string',
          state: 'string',
          street: 'string',
          type: 'main',
          zipCode: 'string'
        }],
        change: {
          date: new Date('2021-12-15T17:38:23.969Z'),
          notes: 'my example change',
          user: 'sample@yukawa.de'
        },
        companyName: 'string',
        created: {
          date: new Date('2021-12-15T17:38:23.969Z'),
          notes: 'my example change',
          user: 'sample@yukawa.de'
        },
        email: sessionToken.username,
        firstName: 'string',
        lang: 'string',
        lastName: 'string',
        mobile: 'string',
        phoneNumber: 'string',
        role: 'string',
        // salutation : 'string',
        shortName: 'string',
        title: 'string',
        vatNumber: 'string'
      },
      username: sessionToken.username
    });
  }
  loadUser(token) {
    return this.createWorker(UserRestEndpoints.loadUser, token);
  }
  changePassword(credentials) {
    return this.createWorker(UserRestEndpoints.changePassword);
  }
  resetPassword(username) {
    return this.createWorker(UserRestEndpoints.resetPassword);
  }
  doWork(workerName, token) {
    return new Observable(observer => {
      setTimeout(() => {
        switch (workerName) {
          case UserRestEndpoints.loadUser:
            observer.next(UserDesignService.generateUserInfo(token));
            break;
          case UserRestEndpoints.changePassword:
            observer.next(new ChangePassword(this.generateChangePassword()));
            break;
        }
        observer.complete();
      }, 0);
      //eslint-disable-next-line
      return {
        unsubscribe: () => {}
      };
    });
  }
  generateChangePassword() {
    return new ChangePassword({
      fqn: 'java.util.Date',
      key: 4711,
      messages: [{
        code: 'ENV.COM',
        level: 2,
        text: 'Swagger Message',
        type: 'string'
      }],
      op: 'ping',
      stamp: new Date().toISOString(),
      took: 0,
      userId: this._injector.get(SessionStoreService).getJSON('user')?.username
    });
  }
}
/** @nocollapse */
UserDesignService.ɵfac = function UserDesignService_Factory(t) {
  return new (t || UserDesignService)(i0.ɵɵinject(i0.Injector));
};
/** @nocollapse */
UserDesignService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UserDesignService,
  factory: UserDesignService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserDesignService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.Injector
    }];
  }, null);
})();
class UserServiceProvider extends ServiceProviderBase {
  constructor(_parentInjector) {
    super(_parentInjector);
  }
  get designFactory() {
    return {
      provide: UserDesignService
    };
  }
  get developmentFactory() {
    return this.productionFactory;
  }
  get productionFactory() {
    return {
      deps: [HttpClient, ConfigService]
    };
  }
}
/** @nocollapse */
UserServiceProvider.ɵfac = function UserServiceProvider_Factory(t) {
  return new (t || UserServiceProvider)(i0.ɵɵinject(i0.Injector));
};
/** @nocollapse */
UserServiceProvider.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UserServiceProvider,
  factory: UserServiceProvider.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserServiceProvider, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.Injector
    }];
  }, null);
})();
const UserInfoServiceFactory = provider => provider.provide(UserService);
class UserService extends ServiceBase {
  constructor(_httpClient, _configService) {
    super();
    this._httpClient = _httpClient;
    this._configService = _configService;
  }
  loadUser(token) {
    return this.createWorker(UserRestEndpoints.loadUser, this._httpClient.get(this._configService.formatUrl('userUrl') + `${UserRestEndpoints.loadUser}${token.username}`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token.accessToken}`
      })
    }));
  }
  changePassword(credentials) {
    return this.createWorker(UserRestEndpoints.changePassword, this._httpClient.post(this._configService.formatUrl('userUrl') + UserRestEndpoints.changePassword, credentials.toJSON()));
  }
  resetPassword(username) {
    return this.createWorker(UserRestEndpoints.changePassword, this._httpClient.post(this._configService.formatUrl('userUrl') + UserRestEndpoints.resetPassword, {
      username
    }));
  }
  doWork(workerName, workerObservable) {
    switch (workerName) {
      case UserRestEndpoints.loadUser:
        return workerObservable.pipe(map$1(response => new UserInfo(response)));
      case UserRestEndpoints.changePassword:
        return workerObservable.pipe(map$1(response => new ChangePassword(response)));
      default:
        throw new ServiceException(TemplateString$1`Request method for '${'workerName'}' not yet implemented.`, {
          workerName: this._configService.formatUrl('userUrl') + workerName
        });
    }
  }
}
/** @nocollapse */
UserService.ɵfac = function UserService_Factory(t) {
  return new (t || UserService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(i1.ConfigService));
};
/** @nocollapse */
UserService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UserService,
  factory: function UserService_Factory(t) {
    let r = null;
    if (t) {
      r = new t();
    } else {
      r = UserInfoServiceFactory(i0.ɵɵinject(UserServiceProvider));
    }
    return r;
  },
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: UserInfoServiceFactory,
      deps: [UserServiceProvider]
    }]
  }], function () {
    return [{
      type: i1$1.HttpClient
    }, {
      type: i1.ConfigService
    }];
  }, null);
})();
class AuthDesignService extends AuthServiceBase {
  constructor(_userInfoService, _injector) {
    super(_userInfoService, null, null);
    this._injector = _injector;
  }
  static generateAccessToken(username) {
    const date = new Date();
    const lifetimeInDays = 7;
    const issuer = {
      sub: 'app@yukawa.de',
      iss: 'https://app.design.cloud.yukawa.de',
      iat: Math.floor(date.getTime() / 1000),
      exp: Math.floor(date.setDate(date.getDate() + lifetimeInDays) / 1000)
    };
    let payload;
    let roleType;
    if (username.indexOf('admin@yukawa') !== -1) {
      roleType = Roles.yukawaAdmin;
    } else if (username.indexOf('@yukawa') !== -1) {
      roleType = Roles.yukawaUser;
    } else {
      roleType = Roles.user;
    }
    const adminProfile = {
      groups: ['ADMINS'],
      groupContexts: [{
        orgId: 'yuk',
        groups: ['ADMINS']
      }],
      defaultOrgId: 'yuk',
      orgId: 'yuk',
      organisationIds: ['yuk']
    };
    const userDetails = {
      groupContexts: [{
        orgId: 'yuk',
        groups: ['ADMINS']
      }],
      orgId: 'yuk',
      organisationIds: ['yuk']
    };
    switch (roleType) {
      case Roles.yukawaAdmin:
        payload = {
          ...issuer,
          scope: ['ROLE_ADMIN'],
          details: adminProfile
        };
        break;
      case Roles.yukawaUser:
        payload = {
          ...issuer,
          scope: ['ROLE_USER'],
          details: userDetails
        };
        break;
      case Roles.none:
      default:
        payload = {
          ...issuer,
          scope: ['ROLE_NONE'],
          details: userDetails
        };
        break;
    }
    const accessToken = `eyJhbGciOiJIUzI1NiJ9.${btoa(JSON.stringify(payload))}.OBZXPVXHPmbgv1syqhewVXtXA7FnYByTMvMH4DZ_hpE`;
    return {
      access_token: accessToken,
      refresh_token: accessToken,
      scope: payload.scope,
      details: payload.details,
      token_type: 'Bearer',
      expires_in: 60 * 60 * 24 * lifetimeInDays,
      access_expires: new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString(),
      refresh_expires: new Date(date.getTime() + 60 * 60 * 24 * lifetimeInDays * 1000).toISOString(),
      username: username
    };
  }
  login(credentials) {
    const response = AuthDesignService.generateAccessToken(credentials.username);
    return this.createWorker('login', of(response));
  }
  refresh(sessionToken) {
    const userInfo = JSON.parse(this._injector.get(SessionStoreService).get('user'));
    return of(AuthDesignService.generateAccessToken(userInfo.username));
  }
}
/** @nocollapse */
AuthDesignService.ɵfac = function AuthDesignService_Factory(t) {
  return new (t || AuthDesignService)(i0.ɵɵinject(UserService), i0.ɵɵinject(i0.Injector));
};
/** @nocollapse */
AuthDesignService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AuthDesignService,
  factory: AuthDesignService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthDesignService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: UserService
    }, {
      type: i0.Injector
    }];
  }, null);
})();
class AuthServiceProvider extends ServiceProviderBase {
  constructor(_parentInjector) {
    super(_parentInjector);
  }
  get designFactory() {
    return {
      provide: AuthDesignService,
      deps: [UserDesignService]
    };
  }
  get developmentFactory() {
    return this.productionFactory;
  }
  get productionFactory() {
    return {
      deps: [HttpClient, UserService, ConfigService]
    };
  }
}
/** @nocollapse */
AuthServiceProvider.ɵfac = function AuthServiceProvider_Factory(t) {
  return new (t || AuthServiceProvider)(i0.ɵɵinject(i0.Injector));
};
/** @nocollapse */
AuthServiceProvider.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AuthServiceProvider,
  factory: AuthServiceProvider.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthServiceProvider, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.Injector
    }];
  }, null);
})();
class LoginRequestEventArgs extends EventArgs {
  constructor(redirectUrl) {
    super();
    this._redirectUrl = redirectUrl;
  }
  get redirectUrl() {
    return this._redirectUrl;
  }
  get signInUrl() {
    return this._signInUrl;
  }
  set signInUrl(value) {
    this._signInUrl = value;
  }
}
var AuthRequestEndpoints;
(function (AuthRequestEndpoints) {
  AuthRequestEndpoints["loadAccount"] = "/account";
  AuthRequestEndpoints["switchOrg"] = "/org";
  AuthRequestEndpoints["refreshToken"] = "/refresh";
  AuthRequestEndpoints["revokeToken"] = "/refresh";
  AuthRequestEndpoints["createToken"] = "/token";
})(AuthRequestEndpoints || (AuthRequestEndpoints = {}));
const authServiceFactory = provider => provider.provide(AuthService);
class AuthService extends AuthServiceBase {
  constructor(_httpClient, _userInfoService, _configService) {
    super(_userInfoService, _configService, _httpClient);
  }
  login(credentials) {
    return this.createWorker('login', this._httpClient?.post(this._configService?.formatUrl('securityUrl') + AuthRequestEndpoints.createToken, credentials.toJSON()));
  }
  refresh(sessionToken) {
    return this._httpClient?.get(this._configService?.formatUrl('securityUrl') + AuthRequestEndpoints.refreshToken, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${sessionToken.refresh_token}`
      })
    }).pipe(
    // update existing token with server response
    map$1(refreshResponse => ({
      ...sessionToken,
      ...refreshResponse
    })));
  }
}
/** @nocollapse */
AuthService.ɵfac = function AuthService_Factory(t) {
  return new (t || AuthService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(UserService), i0.ɵɵinject(i1.ConfigService));
};
/** @nocollapse */
AuthService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AuthService,
  factory: function AuthService_Factory(t) {
    let r = null;
    if (t) {
      r = new t();
    } else {
      r = authServiceFactory(i0.ɵɵinject(AuthServiceProvider));
    }
    return r;
  },
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: authServiceFactory,
      deps: [AuthServiceProvider]
    }]
  }], function () {
    return [{
      type: i1$1.HttpClient
    }, {
      type: UserService
    }, {
      type: i1.ConfigService
    }];
  }, null);
})();
class SessionService {
  constructor(store, auth) {
    this.store = store;
    this.auth = auth;
  }
  get sessionChanged() {
    return Session.sessionChanged;
  }
  get session() {
    return Session.instance;
  }
  async restore() {
    return Session.restore(this);
  }
  login(credentials) {
    return Session.instance.login(this, credentials);
  }
  refresh() {
    return Session.refresh(this);
  }
  logout(options) {
    return Session.instance.logout(this, options);
  }
  async validate() {
    return this.auth.isAuthenticated;
  }
}
/** @nocollapse */
SessionService.ɵfac = function SessionService_Factory(t) {
  return new (t || SessionService)(i0.ɵɵinject(SessionStoreService), i0.ɵɵinject(AuthService));
};
/** @nocollapse */
SessionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SessionService,
  factory: SessionService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionService, [{
    type: Injectable
  }], function () {
    return [{
      type: SessionStoreService
    }, {
      type: AuthService
    }];
  }, null);
})();
class AuthGuardService {
  constructor(_sessionService) {
    this._sessionService = _sessionService;
  }
  get lastForbiddenRoute() {
    return this._loginRequestEventArgs?.redirectUrl;
  }
  async canActivate(routeSnapshot, statesSnapShot) {
    return await this.validate(statesSnapShot.url);
  }
  async canActivateChild(childRoute, state) {
    return this.validate(state.url);
  }
  canLoad(route, segments) {
    return this.validate('/');
  }
  async validate(redirectUrl) {
    if (!(await this._sessionService.validate())) {
      if (this.lastForbiddenRoute === redirectUrl) {
        if (redirectUrl.startsWith(this._loginRequestEventArgs?.signInUrl)) {
          return true;
        }
      }
      this._loginRequestEventArgs = new LoginRequestEventArgs(redirectUrl);
      await this._sessionService.auth.loginRequest.invoke(this._loginRequestEventArgs);
      return false;
    }
    return true;
  }
}
/** @nocollapse */
AuthGuardService.ɵfac = function AuthGuardService_Factory(t) {
  return new (t || AuthGuardService)(i0.ɵɵinject(SessionService));
};
/** @nocollapse */
AuthGuardService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AuthGuardService,
  factory: AuthGuardService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuardService, [{
    type: Injectable
  }], function () {
    return [{
      type: SessionService
    }];
  }, null);
})();

/**
 * Adds authorization header with jwt token if available.
 */
class AuthInterceptor {
  constructor(_configService, _sessionService) {
    this._configService = _configService;
    this._sessionService = _sessionService;
  }
  intercept(request, next) {
    if (AUTH_INTERCEPTOR_OPTIONS.enabled && Session.instance instanceof Session.SessionConnected && request.url.startsWith(this._configService.getBaseUrl())) {
      if (AUTH_INTERCEPTOR_OPTIONS.refreshEnabled && this._refreshObservable == null) {
        AUTH_INTERCEPTOR_OPTIONS.enabled = false;
        this._refreshObservable = this._sessionService.refresh();
        AUTH_INTERCEPTOR_OPTIONS.enabled = true;
      }
      if (AUTH_INTERCEPTOR_OPTIONS.refreshEnabled && this._refreshObservable != null) {
        return this._refreshObservable.pipe(tap(() => this._refreshObservable = null), switchMap(response => this.handle(response, request, next)));
      }
      return this.handle(Session.instance, request, next);
    }
    return next.handle(request);
  }
  handle(session, request, next) {
    if (session instanceof Session.SessionDisconnected) {
      this.signOut();
      return throwError(() => new SessionException('Session expired'));
    }
    if (!request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${Session.instance.user?.authToken.accessToken}`
        }
      });
      // Response
      return next.handle(request).pipe(catchError(error => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.signOut();
        }
        return throwError(error);
      }));
    }
    return next.handle(request);
  }
  signOut() {
    // Sign out
    this._sessionService.logout();
    // Reload the app
    location.reload();
  }
}
/** @nocollapse */
AuthInterceptor.ɵfac = function AuthInterceptor_Factory(t) {
  return new (t || AuthInterceptor)(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(SessionService));
};
/** @nocollapse */
AuthInterceptor.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AuthInterceptor,
  factory: AuthInterceptor.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.ConfigService
    }, {
      type: SessionService
    }];
  }, null);
})();
class NoAuthGuardService extends AuthGuardService {
  constructor(sessionService) {
    super(sessionService);
  }
  async validate(redirectUrl) {
    if (await this._sessionService.validate()) {
      if (redirectUrl.startsWith(this._loginRequestEventArgs?.signInUrl)) {
        return true;
      }
      this._loginRequestEventArgs = new LoginRequestEventArgs(redirectUrl);
      this._sessionService.auth.loginRequest.invoke(this._loginRequestEventArgs);
      return false;
    }
    return true;
  }
}
/** @nocollapse */
NoAuthGuardService.ɵfac = function NoAuthGuardService_Factory(t) {
  return new (t || NoAuthGuardService)(i0.ɵɵinject(SessionService));
};
/** @nocollapse */
NoAuthGuardService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NoAuthGuardService,
  factory: NoAuthGuardService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NoAuthGuardService, [{
    type: Injectable
  }], function () {
    return [{
      type: SessionService
    }];
  }, null);
})();
class SessionModule {}
/** @nocollapse */
SessionModule.ɵfac = function SessionModule_Factory(t) {
  return new (t || SessionModule)();
};
/** @nocollapse */
SessionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SessionModule
});
/** @nocollapse */
SessionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [SessionService, NoAuthGuardService, AuthGuardService, {
    provide: HTTP_INTERCEPTORS,
    useExisting: AuthInterceptor,
    multi: true
  }]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      providers: [SessionService, NoAuthGuardService, AuthGuardService, {
        provide: HTTP_INTERCEPTORS,
        useExisting: AuthInterceptor,
        multi: true
      }],
      imports: [],
      exports: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of session
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Account, AccountStatus, Address, AuthGuardService, AuthInterceptor, AuthRequestEndpoints, AuthService, AuthServiceProvider, AuthToken, ChangePassword, Credentials, DateUtils, LoginRequestEventArgs, NoAuthGuardService, Person, Role, Roles, Session, SessionChangedEventArgs, SessionException, SessionModule, SessionService, SessionStoreService, UnknownUserRoleException, User, UserInfo, UserInfoServiceFactory, UserRestEndpoints, UserService, UserServiceProvider, WebToken, WebTokenException, authServiceFactory };
