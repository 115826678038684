var FileType;
(function (FileType) {
    FileType["FILE"] = "FILE";
    FileType["DIRECTORY"] = "DIRECTORY";
})(FileType || (FileType = {}));

var ImageSize;
(function (ImageSize) {
    ImageSize["DEFAULT"] = "DEFAULT";
    ImageSize["SMALL"] = "SMALL";
    ImageSize["MEDIUM"] = "MEDIUM";
    ImageSize["LARGE"] = "LARGE";
})(ImageSize || (ImageSize = {}));

class ChainContext {
    constructor(data) {
        this.init(data);
    }
    name;
    shortName;
    desc;
    baseUrl;
    tokenUrl;
    modules;
    init(data) {
        Object.assign(this, data);
        for (let idx = 0; idx < this.modules.length; idx++) {
            let module = this.modules[idx];
            module.apiUrl = module.apiUrl || this.baseUrl + '/' + module.reg;
        }
    }
}

/** @since 2.0.7 */
class BaseRealm {
    static ROLE_ADMIN = "ROLE_ADMIN";
    static ROLE_USER = "ROLE_USER";
    static ROLE_INFO = "ROLE_INFO";
    static ROLE_MODULE = "ROLE_MODULE";
}

var DetailLevel;
(function (DetailLevel) {
    DetailLevel[DetailLevel["DEBUG"] = 0] = "DEBUG";
    DetailLevel[DetailLevel["INFO"] = 1] = "INFO";
    DetailLevel[DetailLevel["WARN"] = 2] = "WARN";
    DetailLevel[DetailLevel["ERROR"] = 3] = "ERROR";
    DetailLevel[DetailLevel["FATAL"] = 30] = "FATAL";
    DetailLevel[DetailLevel["MAX"] = 9999] = "MAX";
})(DetailLevel || (DetailLevel = {}));
var ResultCode;
(function (ResultCode) {
    ResultCode["OK"] = "OK";
    ResultCode["IO"] = "IO";
    ResultCode["VALID"] = "VALID";
    ResultCode["INVALID"] = "INVALID";
    ResultCode["DUPLICATE"] = "DUPLICATE";
    ResultCode["DATA_ACCESS"] = "DATA_ACCESS";
    ResultCode["NOT_FOUND"] = "NOT_FOUND";
    ResultCode["UNKNOWN"] = "UNKNOWN";
    ResultCode["DELETE"] = "DELETE";
})(ResultCode || (ResultCode = {}));
class AbstractResult {
    operation;
    fqn;
    messages = [];
    stamp;
    constructor(data) {
        Object.assign(this, data);
    }
    /*  public constructor(operation: string, fqn: string) {
          this.operation = operation;
          this.fqn = fqn;
          this.stamp = new Date();
      }*/
    hasError() {
        return this.findDetail();
    }
    hasWarn() {
        return this.findDetail(DetailLevel.WARN, DetailLevel.ERROR);
    }
    findDetail(minLevel = DetailLevel.ERROR, maxLevel = DetailLevel.MAX) {
        var details = this.messages;
        if (details) {
            for (var i = 0; i < details.length; i++) {
                var msg = details[i];
                //console.log("AbstractResult findDetail [%d,%d] %o ", minLevel, maxLevel, msg);
                if (msg.level >= minLevel && msg.level <= maxLevel)
                    return msg;
            }
        }
        return null;
    }
    findMaxDetail() {
        var max = null;
        var details = this.messages;
        if (details != null && details.length) {
            max = details[0];
            for (var idx = 0; idx < details.length; idx++) {
                let detail = details[idx];
                if (detail.level >= max.level) {
                    max = detail;
                }
            }
        }
        return max;
    }
    error(text, code = null, type = null) {
        let detail = { text: text, code: code, type: type, level: DetailLevel.ERROR };
        this.messages.push(detail);
        return detail;
        // return new ResultDetail(text, code, type, DetailLevel.ERROR);
    }
    warn(text, code = null, type = null) {
        let detail = { text: text, code: code, type: type, level: DetailLevel.WARN };
        this.messages.push(detail);
        return detail;
    }
    info(text, code = null, type = null) {
        let detail = { text: text, code: code, type: type, level: DetailLevel.INFO };
        this.messages.push(detail);
        return detail;
    }
    ok(text, type) {
        let detail = { text: text, code: ResultCode.OK, type: type, level: DetailLevel.INFO };
        this.messages.push(detail);
        return detail;
    }
    static fromData(data) {
        //console.log("Setting", "fromData => ", data);
        // [ww] hack: fallback
        // noinspection TypeScriptValidateTypes
        //return new AbstractResult(data);
        throw new Error("");
    }
}
class EditResult extends AbstractResult {
    key;
    constructor(data) {
        super(data);
    }
    /*  constructor(key: string, operation: string, fqn: string) {
        super(operation, fqn);
        this.key = key;
    }*/
    static fromData(data) {
        //console.log("Setting", "fromData => ", data);
        return new EditResult(data);
    }
}
class QueryResult extends AbstractResult {
    items = [];
    rows;
    hasMore;
    constructor(data) {
        super(data);
        this.items = data.items;
    }
    static fromData(data) {
        return new QueryResult(data);
    }
}
class ErrorResult extends AbstractResult {
    exception;
    constructor(data) {
        super(data);
    }
    static fromData(data) {
        return new ErrorResult(data);
    }
}

/**
 * copy of chaos-sys-type (ts) <br/>
 * changes should in this file should copied over to chaos-sys libs
 * @author ww
 * @deprecated use chain-base-angular-core
 */
class SysType {
    // @toDo [ww] package chaos-sys + chaos-front for angular
    constructor() {
    }
    static isArray(source) {
        return source instanceof Array;
    }
    static toArray(value) {
        if (!value) {
            return [];
        }
        if (SysType.isArray(value))
            return value;
        return [value];
    }
    static isDate(source) {
        return source instanceof Date;
    }
    static isPrimitive(value) {
        let type = typeof value;
        return type === 'string' || type === 'number' || type === 'boolean' || SysType.isDate(value);
    }
    // -----------------------------------------------------------------------------------------------------------------
    static objectMix(destination, source, cloneDest = false) {
        let target, property;
        if (SysType.isArray(source)) {
            //console.debug("objectMix source array");
            if (cloneDest) {
                source = [destination].concat(source);
                target = {};
            }
            else
                target = destination;
            for (let idx = 0; idx < source.length; idx++) {
                //console.group("objectMix("+idx+")");
                target = SysType.objectMix(target, source[idx]);
                //console.groupEnd()
            }
            return target;
        }
        if (cloneDest) {
            //target = SysType.objectMix({} , destination, false);
            target = SysType.objectClone(destination);
        }
        else {
            target = destination || {};
        }
        //noinspection JSLint
        for (property in source) {
            if (!source.hasOwnProperty(property))
                continue;
            let src = source[property];
            if (SysType.isDate(src)) {
                target[property] = SysType.objectClone(src) || destination[property];
                continue;
            }
            if (SysType.isPrimitive(src)) {
                //console.debug("%s <= %s || %s", property, JSON.stringify(src || 'no src'),JSON.stringify(destination ? destination[property] : 'no dest')
                //console.debug("%s <= %s || %s", property, JSON.stringify(src || 'no src'), destination ? JSON.stringify(destination[property]) : 'no dest' );
                //noinspection JSUnfilteredForInLoop
                target[property] = src || destination[property];
                continue;
            }
            if (SysType.isArray(src)) {
                //console.group(property+"["+src.length+"]");
                //target = cloneDest ? SysType.objectClone(SysType.toArray(destination)) : SysType.toArray(destination);
                let tar = SysType.toArray(destination[property]);
                for (let i = 0; i < src.length; i++) {
                    tar[i] = SysType.objectMix(tar[i], src[i], false);
                }
                target[property] = tar;
                //console.groupEnd();
            }
            else {
                //<debug>
                //console.group(property+" (obj)");
                //</debug>
                target[property] = SysType.objectMix(destination[property], src, false);
                //<debug>
                //console.groupEnd();
                //</debug>
            }
        }
        return target;
    }
    static objectClone(source) {
        if (!source) {
            return source;
        }
        if (SysType.isDate(source)) {
            return new Date(source.valueOf());
        }
        if (SysType.isPrimitive(source)) {
            return source;
        }
        let target;
        if (SysType.isArray(source)) {
            target = [];
            for (let i = 0; i < source.length; i++) {
                target[i] = SysType.objectClone(source[i]);
            }
            return target;
            //return source.slice();
        }
        target = {};
        for (let property in source) {
            if (!source.hasOwnProperty(property))
                continue;
            target[property] = SysType.objectClone(source[property]);
        }
        return target;
    }
}

/*
 * Public API Surface of chain-base-core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractResult, BaseRealm, ChainContext, DetailLevel, EditResult, ErrorResult, FileType, ImageSize, QueryResult, ResultCode, SysType };

