import { ObjectBase } from "./..";
export class KeyValuePair extends ObjectBase {
  get key() {
    return this._key;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
  }

  constructor(key, value) {
    super(String(key));
    this._key = void 0;
    this._value = void 0;
    this._key = key;
    this._value = value;
  }

  toString() {
    return `${super.toString()} -> ${this.value}`;
  }

}
