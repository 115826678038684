import { ArgumentNullException, isString, ObjectBase, TemplateString } from '@awesome-nodes/object';
import InjectionFactory from "./..";
import { isProviderConfig } from "./InjectionProviderConfig";
import { DuplicateInjectionProviderException, UnknownInjectionProviderException, UnknownInjectionProviderTokenException } from "./InjectionProviderException";
import { InjectionToken } from "./InjectionToken";
import { DuplicateInjectionTokenException, UnknownInjectionTokenException } from "./InjectionTokenException";
import { convertFromProviderConfig } from "../providers/ProviderConfig";
export class InjectionScope extends ObjectBase {
  get tokens() {
    return this._tokens.slice();
  }

  get providers() {
    return Array.from(this._providers.values());
  }

  get parent() {
    return this._parent;
  }

  constructor(name, _parent) {
    super(name);
    this._parent = _parent;
    this._tokens = new Array();
    this._providers = new Map();
  }

  addSubscope(scopeName) {
    return InjectionFactory.createScope(scopeName, this);
  }

  locateToken(token, scope = this) {
    token = InjectionToken.normalize(token);

    const find = scope._tokens.find(_token => _token.key == token);

    return !find && scope._parent ? this.locateToken(token, scope._parent) : find;
  }

  addToken(...tokens) {
    for (const _newToken of tokens) {
      if (this._tokens.find(_token => String(_token) == InjectionToken.toString(_newToken))) {
        throw new DuplicateInjectionTokenException(this, _newToken, TemplateString`Can not register token '${'token'}' within scope '${'scope'}'. `);
      }
    }

    const injectionTokens = tokens.map(t => new InjectionToken(t, this));

    this._tokens.push(...injectionTokens);

    return injectionTokens.length == 1 ? injectionTokens[0] : injectionTokens;
  }

  addProvider(token, provider, override = false) {
    if (isProviderConfig(token)) {
      provider = convertFromProviderConfig(token, this);
      token = provider.token;
    } else if (!provider) throw new ArgumentNullException('Can not add provider.', 'provider');

    let injectionToken;

    if (!(token instanceof InjectionToken)) {
      if (!token || !isString(token) || !token.length) throw new ArgumentNullException(`Can not add provider '${provider.name}'. `, 'token');
      injectionToken = this.locateToken(token);

      if (!injectionToken) {
        throw new UnknownInjectionProviderTokenException(this, token, provider, `Cannot add provider '${provider.name}'. `);
      }
    } else injectionToken = token;

    if (!override && this._providers.has(injectionToken.key)) {
      throw new DuplicateInjectionProviderException(this, token, provider, `Cannot override provider '${this._providers.get(injectionToken.key).name}' with provider '${provider.name}' of token '${injectionToken}'. `);
    }

    this._providers.set(injectionToken.key, provider);

    return provider;
  }

  inject(token, defaultValue, scope) {
    let injectionToken;
    token = InjectionToken.normalize(token);
    if (token instanceof InjectionToken) injectionToken = token;else {
      injectionToken = this.locateToken(token);

      if (!injectionToken) {
        if (defaultValue) return defaultValue;
        throw new UnknownInjectionTokenException(scope || this, token, 'Dependency inject failed. ');
      }
    }

    let provider = this._providers.get(injectionToken.key);

    let value;

    if (!provider) {
      const injectionScopes = InjectionFactory.scopes;
      let tokenScope = injectionScopes.find(_scope => _scope.name == String(InjectionToken.simplify(token)) ? _scope : undefined);

      if (!tokenScope) {
        tokenScope = injectionScopes.find(_scope => _scope.parent == this ? _scope : undefined);
      }

      if (tokenScope && (provider = tokenScope._providers.get(injectionToken.key))) value = provider.provide();

      if (!provider && !this._parent) {
        if (defaultValue) return defaultValue;
        throw new UnknownInjectionProviderException(scope || this, token, 'Dependency inject failed. ');
      }
    } else value = provider.provide();

    if (!value && this._parent) value = this._parent.inject(injectionToken, defaultValue, this);
    return value;
  }

  toString() {
    let s = super.toString();
    if (this._parent) s = `${this._parent.toString()}/${s}`;
    return s;
  }

  static toString(scope, parent = InjectionFactory.mainScope) {
    return isString(scope) && scope.indexOf('/') == -1 && parent != null ? `${parent}/${scope}` : String(scope);
  }

  static get(token, parentScope) {
    return InjectionFactory.createScope(String(InjectionToken.simplify(token)), parentScope);
  }

}
