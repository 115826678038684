import { ObjectBase } from '@awesome-nodes/object';
import { ENVIRONMENT_TOKEN } from '@awesome-nodes/object/data';
export class ServiceProviderBase extends ObjectBase {
  provide(provide) {
    const factoryProvider = this.constructor.createFactoryProvider(provide, this);
    if (!factoryProvider.provide) factoryProvider.provide = provide;
    return this.inject({
      provide: factoryProvider.provide,
      useFactory: factoryProvider.useFactory,
      deps: factoryProvider.deps
    });
  }

  static createFactoryProvider(provide, provider = new this()) {
    let factoryProvider;

    switch (ENVIRONMENT_TOKEN.value) {
      case null:
      case undefined:
        factoryProvider = provider.designFactory;
        break;

      case false:
        factoryProvider = provider.developmentFactory;
        break;

      default:
        factoryProvider = provider.productionFactory;
    }

    if (!factoryProvider.provide) factoryProvider.provide = provide;
    return {
      provide: factoryProvider.provide,
      useFactory: (...deps) => {
        return new (factoryProvider.provide.bind(null, ...deps))();
      },
      deps: factoryProvider.deps || []
    };
  }

  static createClassProvider() {
    const providerBase = new this();
    const productionFactory = providerBase.productionFactory;
    let factoryProvider;

    switch (ENVIRONMENT_TOKEN.value) {
      case null:
      case undefined:
        factoryProvider = providerBase.designFactory;
        break;

      case false:
        factoryProvider = providerBase.developmentFactory;
        break;

      default:
        factoryProvider = productionFactory;
    }

    return {
      provide: productionFactory.provide,
      useClass: factoryProvider.provide
    };
  }

}
