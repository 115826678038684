function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { isString } from "./TypeChecks";
export class Exception extends Error {
  get parent() {
    return this._innerException;
  }

  get stackTrace() {
    return this.stack;
  }

  constructor(message, messageTemplateValues = {}, _innerException) {
    super(isString(message) ? message : message(_objectSpread(_objectSpread({}, messageTemplateValues), {}, {
      parent: _innerException
    })));
    this._innerException = _innerException;
    Exception.setPrototype(this);
  }

  toString() {
    return `${this.constructor.name}: ${this.message}`;
  }

  static setPrototype(instance) {
    Object.setPrototypeOf(instance, this.prototype);
  }

}
