import { InjectionProvider } from "../injection/InjectionProvider";
export class TokenProvider extends InjectionProvider {
  constructor(scope, token, _mappedToken) {
    super(scope, token, null);
    this._mappedToken = _mappedToken;
  }

  provide() {
    return this._value || (this._value = this._scope.inject(this._mappedToken));
  }

}
