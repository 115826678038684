function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export function TemplateString(strings, ...keys) {
  const appendedTemplates = [];
  const prependTemplates = [];
  let extraValues = {};
  return Object.assign((...values) => {
    const dict = ExtendValues(values);
    const result = [strings instanceof Array ? strings[0] : strings];
    keys.forEach((key, i) => {
      result.push(typeof key == 'number' ? values[key] : dict[key], strings[i + 1]);
    });
    return prependTemplates.map(_prepend => typeof _prepend == 'function' ? _prepend(values) : _prepend).concat(result).concat(appendedTemplates.map(_append => typeof _append == 'function' ? _append(values) : _append)).join('');
  }, {
    strings,
    keys,
    append: Append,
    prepend: Prepend
  });

  function Append(templateString, ...values) {
    if (templateString.length) {
      appendedTemplates.push(templateString);
      extraValues = ExtendValues(values);
    }

    return this;
  }

  function Prepend(templateString, ...values) {
    if (templateString.length) {
      prependTemplates.push(templateString);
      extraValues = ExtendValues(values);
    }

    return this;
  }

  function ExtendValues(values) {
    return _objectSpread(_objectSpread({}, extraValues), values[values.length - 1] || {});
  }
}
