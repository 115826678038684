import { ArgumentNullException, DataTypes, TemplateString } from '@awesome-nodes/object';
import InjectionFactory from "./..";
import { InjectionProvider } from "../injection/InjectionProvider";
import { isConstructorProvider } from "../injection/InjectionProviderConfig";
import { InjectionProviderConfigException } from "../injection/InjectionProviderConfigException";
import { InjectionToken } from "../injection/InjectionToken";
import { ClassProvider } from "./ClassProvider";
import { FactoryProvider } from "./FactoryProvider";
import { TokenProvider } from "./TokenProvider";
export function createProviderConfig(token, value = token, scope = InjectionFactory.mainScope) {
  let provider;

  switch (DataTypes.Parse(value)) {
    case DataTypes.Boolean:
    case DataTypes.Number:
    case DataTypes.BigInt:
    case DataTypes.String:
    case DataTypes.Date:
    case DataTypes.RegExp:
    case DataTypes.Array:
    case DataTypes.Object:
    case DataTypes.Error:
    case DataTypes.Instance:
      if (!(value instanceof InjectionToken)) {
        provider = {
          provide: token,
          useValue: value,
          scope
        };
        break;
      }

    case DataTypes.Symbol:
      provider = {
        provide: token,
        useToken: InjectionToken.simplify(value),
        scope
      };
      break;

    case DataTypes.Type:
      provider = {
        provide: token,
        useClass: value,
        scope
      };
      break;

    case DataTypes.Function:
      provider = {
        provide: token,
        useFactory: value,
        scope
      };
      break;

    case DataTypes.Null:
    case DataTypes.Undefined:
    default:
      throw new ArgumentNullException(TemplateString`Can not create provider configuration for token '${InjectionToken.toString(token)}'. `, 'value');
  }

  return provider;
}
export function createInjectionProvider(token, value = token, scope = InjectionFactory.mainScope) {
  let provider;

  switch (DataTypes.Parse(value)) {
    case DataTypes.Boolean:
    case DataTypes.Number:
    case DataTypes.BigInt:
    case DataTypes.String:
    case DataTypes.Date:
    case DataTypes.RegExp:
    case DataTypes.Array:
    case DataTypes.Object:
    case DataTypes.Error:
    case DataTypes.Instance:
      if (!(value instanceof InjectionToken)) {
        provider = new InjectionProvider(scope, token, value);
        break;
      }

    case DataTypes.Symbol:
      provider = new TokenProvider(scope, token, InjectionToken.simplify(value));
      break;

    case DataTypes.Type:
      provider = new ClassProvider(scope, token, value);
      break;

    case DataTypes.Function:
      provider = new FactoryProvider(scope, token, value);
      break;

    case DataTypes.Null:
    case DataTypes.Undefined:
    default:
      throw new ArgumentNullException(TemplateString`Can not create provider configuration for token '${InjectionToken.toString(token)}'. `, 'value');
  }

  return provider;
}
export function convertFromProviderConfig(provider, scope = InjectionFactory.mainScope) {
  let providerType;
  let value;
  const anyProvider = provider;
  if ((value = anyProvider.useValue) !== undefined) providerType = InjectionProvider;else if ((value = anyProvider.useFactory) !== undefined) providerType = FactoryProvider;else if ((value = anyProvider.useToken) !== undefined) providerType = TokenProvider;else if (isConstructorProvider(anyProvider) && ((value = anyProvider.provide) || (value = anyProvider.useClass)) !== undefined) providerType = ClassProvider;else {
    throw new InjectionProviderConfigException(provider.scope || scope, provider.provide, provider, TemplateString`Can not convert an unknown provider configuration '${'provider'}' for token '${'token'}' to a known injection provider.`);
  }
  return new providerType(scope != InjectionFactory.mainScope ? scope : provider.scope || scope, provider.provide, value, ...(provider.deps || []));
}
