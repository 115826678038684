import { InstanceProvider } from "./InstanceProvider";
export class FactoryProvider extends InstanceProvider {
  constructor(scope, token, factory, ...dependencies) {
    super(scope, token, factory, ...dependencies);
  }

  createInstance() {
    return this._constructor.bind(null, ...this.resolveDependencies())();
  }

}
