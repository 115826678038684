export function isPrototypeOf(constructor, prototype) {
  if (constructor == prototype || constructor instanceof prototype) return true;
  const prototypeOf = Object.getPrototypeOf(constructor);
  if (prototypeOf) return isPrototypeOf(prototypeOf, prototype);
  return false;
}
export function isFunction(value) {
  return value && typeof value === 'function' && !isConstructor(value);
}
export function isConstructor(value) {
  return (value && typeof value === 'function' && value.prototype && value.prototype.constructor) === value;
}
export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}
export function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array;
}
export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}
export function isNumber(value) {
  return typeof value === 'number' && isFinite(value);
}
export function isRegExp(value) {
  return value && typeof value === 'object' && value.constructor === RegExp;
}
export function isError(value) {
  return value instanceof Error && typeof value.message !== 'undefined';
}
