import { InstanceProvider } from "./InstanceProvider";
export class ClassProvider extends InstanceProvider {
  constructor(scope, token, value, ...dependencies) {
    super(scope, token, value, ...dependencies);
  }

  createInstance() {
    return Reflect.construct(this._constructor, this.resolveDependencies());
  }

}
