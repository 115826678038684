import { isConstructor, isObject } from '@awesome-nodes/object';
import { InjectionProvider } from "./InjectionProvider";
export function isProviderConfig(object) {
  return isObject(object) && !(object instanceof InjectionProvider) && (isConstructorProvider(object) || isClassProvider(object) || isValueProvider(object) || isFactoryProvider(object) || isTokenProvider(object));
}
export function isClassProvider(provider) {
  return provider.useClass !== undefined;
}
export function isValueProvider(provider) {
  return provider.useValue !== undefined;
}
export function isFactoryProvider(provider) {
  return provider.useFactory !== undefined;
}
export function isTokenProvider(provider) {
  return provider.useToken !== undefined;
}
export function isConstructorProvider(provider) {
  return isObject(provider) && isConstructor(provider.provide);
}
