import { Exception } from "./Exception";
export class ArgumentException extends Exception {
  get paramName() {
    return this._paramName;
  }

  constructor(message, _paramName, innerException) {
    super(message, {
      paramName: _paramName
    }, innerException);
    this._paramName = _paramName;
  }

  toString() {
    let toString = super.toString();
    if (this._paramName && toString.indexOf(this._paramName) == -1) toString += `\nParameter Name: ${this._paramName}`;
    return toString;
  }

}
