import { InjectionFactoryException } from "./InjectionFactoryException";
import 'reflect-metadata';
export function Inject(token, scope) {
  return (target, _, index) => {
    setInjectionToken(target, {
      scope,
      token
    }, index);
  };
}
const INJECT_METADATA_KEY = Symbol('INJECT_KEY');
const REFLECT_PARAMS = 'design:paramtypes';
export function setInjectionToken(target, metadata, index) {
  Reflect.defineMetadata(INJECT_METADATA_KEY, metadata, target, `index-${index}`);
}
export function getInjectionToken(target, index) {
  return Reflect.getMetadata(INJECT_METADATA_KEY, target, `index-${index}`);
}
export function getInjectedParams(target) {
  const argTypes = Reflect.getMetadata(REFLECT_PARAMS, target);
  if (argTypes === undefined) return [];
  return argTypes.map((argType, index) => {
    if (argType === undefined) {
      throw new InjectionFactoryException(`Injection error. Recursive dependency detected in constructor for type ${target.name} with parameter at index ${index}`);
    }

    const injectionMetadata = getInjectionToken(target, index);
    const overrideToken = injectionMetadata && injectionMetadata.token;
    const actualToken = overrideToken === undefined ? argType : overrideToken;
    return {
      scope: injectionMetadata && injectionMetadata.scope,
      index,
      token: actualToken,
      inject: !!injectionMetadata
    };
  });
}
