import { TemplateString } from '@awesome-nodes/object';
import InjectionFactory from "./..";
import { getInjectedParams } from "../Inject.decorator";
import { getInjectableMetadata } from "../Injectable.decorator";
import { InjectionProvider } from "../injection/InjectionProvider";
import { UnknownInjectionProviderTokenException } from "../injection/InjectionProviderException";
import { InjectionScope } from "../injection/InjectionScope";
import { InjectionToken } from "../injection/InjectionToken";
export class InstanceProvider extends InjectionProvider {
  get dependencies() {
    return this._dependencies;
  }

  constructor(scope, token, _constructor, ...dependencies) {
    super(scope, token, null);
    this._constructor = _constructor;
    this._dependencies = void 0;
    this._dependencies = dependencies;
  }

  resolveInjectDependencies() {
    const dependencies = [];

    for (const _param of getInjectedParams(this._constructor)) {
      if (typeof _param.token != 'function') continue;

      if (!_param.inject || this._dependencies.some(_dep => InjectionToken.compare(_dep, _param.token))) {
        dependencies.push(_param.token == InjectionScope ? this._scope : this._scope.inject(_param.token));
        continue;
      }

      const injectableMetadata = getInjectableMetadata(_param.token);
      let tokenScope;

      if (!injectableMetadata) {
        const token = this._scope.locateToken(_param.token);

        if (!token) {
          throw new UnknownInjectionProviderTokenException(this._scope, _param.token, this._constructor, TemplateString`Can not resolve injection tokens for type '${'provider'}'. The type of the ${'index'}. constructor parameter '${'parameter'} has no token defined.'`, {
            index: _param.index + 1,
            parameter: _param.token.name
          });
        }

        tokenScope = token.scope;
      } else tokenScope = injectableMetadata.scope;

      let providerScope;

      if (_param.scope) {
        providerScope = InjectionFactory.scopes.find(_scope => _scope == _param.scope || String(_scope) == String(_param.scope)) || InjectionFactory.locateScope(_param.scope);
      } else {
        const provider = this._scope.providers.find(_provider => InjectionToken.compare(_provider.token, _param.token));

        if (provider) providerScope = this._scope;
      }

      if (!providerScope) providerScope = InjectionFactory.locateScope(tokenScope);
      dependencies.push(providerScope.inject(_param.token));
    }

    return dependencies;
  }

  resolveDependencies() {
    const dependencies = this.resolveInjectDependencies();
    if (!dependencies.length && this._dependencies.length) dependencies.push(...this._dependencies.map(_dep => this._scope.inject(_dep)));
    if (!dependencies.some(_dep => _dep == this._scope)) dependencies.push(this._scope);
    return dependencies;
  }

  provide() {
    return this._value || (this._value = this.createInstance());
  }

}
