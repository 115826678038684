function _classPrivateFieldLooseBase(receiver, privateKey) { if (!Object.prototype.hasOwnProperty.call(receiver, privateKey)) { throw new TypeError("attempted to use private field on non-instance"); } return receiver; }

var id = 0;

function _classPrivateFieldLooseKey(name) { return "__private_" + id++ + "_" + name; }

import { EventArgs } from '@awesome-nodes/object';
import { Disposable } from "./Disposable";
import { BehaviorSubject, throwError } from 'rxjs';

var _thisArg2 = _classPrivateFieldLooseKey("_thisArg");

var _actionObservable = _classPrivateFieldLooseKey("_actionObservable");

var _actionSubscription = _classPrivateFieldLooseKey("_actionSubscription");

var _nextResult = _classPrivateFieldLooseKey("_nextResult");

var _nextResult$ = _classPrivateFieldLooseKey("_nextResult$");

var _actionResult = _classPrivateFieldLooseKey("_actionResult");

var _actionResult$ = _classPrivateFieldLooseKey("_actionResult$");

var _onProgress = _classPrivateFieldLooseKey("_onProgress");

var _onError = _classPrivateFieldLooseKey("_onError");

var _onComplete = _classPrivateFieldLooseKey("_onComplete");

export class ActionObserver extends Disposable {
  get action() {
    return _classPrivateFieldLooseBase(this, _actionObservable)[_actionObservable];
  }

  get actionResult() {
    return _classPrivateFieldLooseBase(this, _actionResult$)[_actionResult$];
  }

  get actionResultValue() {
    return _classPrivateFieldLooseBase(this, _actionResult)[_actionResult].getValue();
  }

  get actionResultAvailable() {
    return !!this.actionResultValue && this.actionResultValue !== this._defaultResult;
  }

  get nextResult() {
    return _classPrivateFieldLooseBase(this, _nextResult$)[_nextResult$];
  }

  get nextValue() {
    return _classPrivateFieldLooseBase(this, _nextResult)[_nextResult].getValue();
  }

  get nextResultAvailable() {
    return !!this.nextValue && this.nextValue !== this._defaultResult;
  }

  set onProgress(value) {
    _classPrivateFieldLooseBase(this, _onProgress)[_onProgress] = _classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2] ? value.bind(_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2]) : value;
  }

  set onError(value) {
    _classPrivateFieldLooseBase(this, _onError)[_onError] = value;
  }

  set onComplete(value) {
    _classPrivateFieldLooseBase(this, _onComplete)[_onComplete] = _classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2] ? value.bind(_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2]) : value;
  }

  constructor(_thisArg, _defaultResult) {
    super();
    this._defaultResult = _defaultResult;
    Object.defineProperty(this, _thisArg2, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _actionObservable, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _actionSubscription, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _nextResult, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _nextResult$, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _actionResult, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _actionResult$, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _onProgress, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _onError, {
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, _onComplete, {
      writable: true,
      value: void 0
    });
    _classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2] = _thisArg || this;
    _classPrivateFieldLooseBase(this, _nextResult)[_nextResult] = new BehaviorSubject(_defaultResult);
    _classPrivateFieldLooseBase(this, _nextResult$)[_nextResult$] = _classPrivateFieldLooseBase(this, _nextResult)[_nextResult].asObservable();
    _classPrivateFieldLooseBase(this, _actionResult)[_actionResult] = new BehaviorSubject(_defaultResult);
    _classPrivateFieldLooseBase(this, _actionResult$)[_actionResult$] = _classPrivateFieldLooseBase(this, _actionResult)[_actionResult].asObservable();
  }

  observe(action, observer) {
    let observableResult = {};

    if (observer) {
      if (typeof observer == 'function') {
        observableResult = {
          next: result => {
            _classPrivateFieldLooseBase(this, _nextResult)[_nextResult].next(result);

            observer.bind(_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2])(_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2], EventArgs.empty);
          },
          complete: () => {
            _classPrivateFieldLooseBase(this, _actionResult)[_actionResult].next(_classPrivateFieldLooseBase(this, _nextResult)[_nextResult].getValue());
          }
        };
      } else {
        if (_classPrivateFieldLooseBase(this, _onProgress)[_onProgress] && observer.init) _classPrivateFieldLooseBase(this, _onProgress)[_onProgress](_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2], observer.init());

        observableResult.error = e => {
          if (observer.error) observer.error(e);else if (_classPrivateFieldLooseBase(this, _onError)[_onError]) _classPrivateFieldLooseBase(this, _onError)[_onError](e);else console.error(e);
        };

        observableResult.next = result => {
          if (result instanceof Error) {
            observableResult.error(result);
            return;
          }

          _classPrivateFieldLooseBase(this, _nextResult)[_nextResult].next(result);

          if (_classPrivateFieldLooseBase(this, _onProgress)[_onProgress] && observer.next) _classPrivateFieldLooseBase(this, _onProgress)[_onProgress](_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2], observer.next(result));
        };

        observableResult.complete = () => {
          _classPrivateFieldLooseBase(this, _actionResult)[_actionResult].next(_classPrivateFieldLooseBase(this, _nextResult)[_nextResult].getValue());

          if (_classPrivateFieldLooseBase(this, _onComplete)[_onComplete] && observer.complete) _classPrivateFieldLooseBase(this, _onComplete)[_onComplete](_classPrivateFieldLooseBase(this, _thisArg2)[_thisArg2], observer.complete());
        };
      }
    }

    try {
      _classPrivateFieldLooseBase(this, _actionObservable)[_actionObservable] = action();
    } catch (exception) {
      (_classPrivateFieldLooseBase(this, _onError)[_onError] || console.error)(exception);
      _classPrivateFieldLooseBase(this, _actionObservable)[_actionObservable] = throwError(exception);
    }

    if (observer) _classPrivateFieldLooseBase(this, _actionSubscription)[_actionSubscription] = _classPrivateFieldLooseBase(this, _actionObservable)[_actionObservable].subscribe(observableResult);
    return _classPrivateFieldLooseBase(this, _actionObservable)[_actionObservable];
  }

  cancel() {
    _classPrivateFieldLooseBase(this, _actionSubscription)[_actionSubscription] && !_classPrivateFieldLooseBase(this, _actionSubscription)[_actionSubscription].closed && _classPrivateFieldLooseBase(this, _actionSubscription)[_actionSubscription].unsubscribe();
  }

  reset() {
    _classPrivateFieldLooseBase(this, _nextResult)[_nextResult].next(this._defaultResult);

    _classPrivateFieldLooseBase(this, _actionResult)[_actionResult].next(this._defaultResult);
  }

  equals(other) {
    return super.equals(other) && _classPrivateFieldLooseBase(this, _actionResult)[_actionResult] === _classPrivateFieldLooseBase(other, _actionResult)[_actionResult];
  }

  dispose(disposing) {
    if (disposing && !this.isDisposed) {
      this.cancel();

      _classPrivateFieldLooseBase(this, _nextResult)[_nextResult].unsubscribe();

      _classPrivateFieldLooseBase(this, _actionResult)[_actionResult].unsubscribe();
    }

    super.dispose(disposing);
  }

}
