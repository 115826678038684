import { TemplateString } from '@awesome-nodes/object';
import { InjectionScopeException } from "./InjectionScopeException";
import { InjectionToken } from "./InjectionToken";
export class InjectionTokenException extends InjectionScopeException {
  get token() {
    return this._token;
  }

  constructor(scope, _token, message, messageTemplateValues = {}, innerException) {
    super(scope, message, Object.assign(messageTemplateValues, {
      token: InjectionToken.toString(_token)
    }), innerException);
    this._token = _token;
    InjectionTokenException.setPrototype(this);
  }

  toString() {
    let toString = super.toString();
    const token = InjectionToken.toString(this._token);
    if (this._token && toString.indexOf(token) == -1) toString += `\nInjection Token: ${token}`;
    return toString;
  }

}
export class UnknownInjectionTokenException extends InjectionTokenException {
  constructor(scope, token, message, messageTemplateValues) {
    super(scope, token, TemplateString`The injection token '${'token'}' could not be located within scope '${'scope'}'.`.prepend(message), messageTemplateValues);
    UnknownInjectionTokenException.setPrototype(this);
  }

}
export class UnknownInjectionTokenScopeException extends InjectionTokenException {
  constructor(scope, token, message, messageTemplateValues) {
    super(scope, token, TemplateString`The injection scope '${'scope'}' of token '${'token'}' does not exist.`.prepend(message), messageTemplateValues);
    UnknownInjectionTokenScopeException.setPrototype(this);
  }

}
export class DuplicateInjectionTokenException extends InjectionTokenException {
  constructor(scope, token, message, messageTemplateValues) {
    super(scope, token, TemplateString`The injection token '${'token'}' does already exists.`.prepend(message), messageTemplateValues);
    DuplicateInjectionTokenException.setPrototype(this);
  }

}
