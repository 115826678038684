import { ArgumentNullException, isString, ObjectBase } from '@awesome-nodes/object';
import InjectionFactory from "./..";
import { UnknownInjectionProviderScopeException } from "./InjectionProviderException";
import { InjectionToken } from "./InjectionToken";
import { setProviderMetadata } from "../Provider.decorator";
export class InjectionProvider extends ObjectBase {
  get scope() {
    return this._scope;
  }

  get token() {
    return this._token;
  }

  get value() {
    return this._value;
  }

  constructor(scope, _token, _value) {
    super();
    this._token = _token;
    this._value = _value;
    this._scope = void 0;

    if (!scope) {
      throw new ArgumentNullException(`Can not instantiate provider '${this.name}' for token '${InjectionToken.toString(_token)}'. `, 'scope');
    }

    let injectionScope;

    if (isString(scope)) {
      if (!scope.length) throw new ArgumentNullException(`Can not instantiate provider '${this.name}' `, 'scope');
      injectionScope = InjectionFactory.scopes.find(_scope => _scope.toString() == scope);
    } else injectionScope = scope;

    if (!injectionScope) {
      throw new UnknownInjectionProviderScopeException(scope, _token, this, `Can not instantiate provider '${this.name}' `);
    }

    this._scope = injectionScope;
    setProviderMetadata(this.constructor, _token);
  }

  provide() {
    return this._value;
  }

  static toString(provider) {
    return typeof provider == 'function' ? provider.name : provider instanceof InjectionProvider ? provider.name : String(provider);
  }

}
