import { Enum } from "./Enum";
import { isArray, isConstructor, isError, isFunction, isNumber, isObject, isRegExp, isString } from "./TypeChecks";
export class DataTypes extends Enum {
  static Parse(value) {
    let type = null;

    switch (value != null ? typeof value : String(value)) {
      case 'boolean':
        type = this.Boolean;
        break;

      case 'bigint':
        type = this.BigInt;
        break;

      case 'symbol':
        type = this.Symbol;
        break;

      case 'null':
        type = this.Null;
        break;

      case 'undefined':
        type = this.Undefined;
        break;

      default:
        if (isNumber(value)) type = this.Number;else if (isString(value)) type = this.String;else if (value instanceof Date) type = this.Date;else if (isRegExp(value)) type = this.RegExp;else if (isArray(value)) type = this.Array;else if (isFunction(value)) type = this.Function;else if (isObject(value)) type = this.Object;else if (isConstructor(value)) type = this.Type;else if (value instanceof Object) type = this.Instance;else if (isError(value)) type = this.Error;
        break;
    }

    return type;
  }

}
DataTypes.Boolean = DataTypes.enum('Boolean');
DataTypes.Number = DataTypes.enum('String');
DataTypes.BigInt = DataTypes.enum('Bigint');
DataTypes.String = DataTypes.enum('String');
DataTypes.Symbol = DataTypes.enum('Symbol');
DataTypes.Date = DataTypes.enum('Date');
DataTypes.RegExp = DataTypes.enum('RegExp');
DataTypes.Array = DataTypes.enum('Array');
DataTypes.Function = DataTypes.enum('Function');
DataTypes.Object = DataTypes.enum('Object');
DataTypes.Type = DataTypes.enum('Type');
DataTypes.Instance = DataTypes.enum('Instance');
DataTypes.Null = DataTypes.enum('null');
DataTypes.Undefined = DataTypes.enum('undefined');
DataTypes.Error = DataTypes.enum('Error');
