import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { AuthToken } from '@yukawa/chain-main-angular-session/model/auth';
import {
  SessionStoreService,
  UserInfo,
} from '@yukawa/chain-main-angular-session';
import { BehaviorSubject, lastValueFrom, map, Observable, ReplaySubject, Subscription, tap } from 'rxjs';
import { UserFilter } from '@yukawa/chain-main-angular-core';

export enum UserRestEndpoints {
  createUser = '/',
  updateUser = '/',
  loadUser = '/',
  deleteUser = '/',
  synchronizeRoles = '/admin/synchronizeRoles',
  exportUsers = '/export',
  merge = '/merge',
  changePassword = '/password/change',
  resetPassword = '/password/reset',
  queryUsers = '/query',
  registerUser = '/register',
}

@Injectable({ providedIn: 'root' })
export class UserService {
  
  private _httpClient = inject(HttpClient);
  private _configService = inject(ConfigService);
  private _sessionStore = inject(SessionStoreService);

  private _user: ReplaySubject<UserInfo> = new ReplaySubject<UserInfo>(1);
  userList: BehaviorSubject<UserInfo[]> = new BehaviorSubject<UserInfo[]>([]);


  set user(value: UserInfo) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<UserInfo> {
    return this._user.asObservable();
  }

  get userList$(): Observable<UserInfo[]> {
    return this.userList.asObservable();
  }

  get(): Observable<UserInfo> {
    return this._httpClient.get<UserInfo>('api/common/user').pipe(
      tap((user) => {
        this._user.next(user);
      })
    );
  }

  public loadUser(token: AuthToken): Observable<UserInfo> {
    if (this._sessionStore.getJSON('user')) {
      this._user.next(this._sessionStore.getJSON('user') as UserInfo);
      return this._user.asObservable();
    }
    return this._httpClient
      .get<UserInfo>(
        this._configService.formatUrl('userUrl') +
          `${UserRestEndpoints.loadUser}${token.username}`
      )
      .pipe(
        tap((user: UserInfo) => {
          console.debug('Userservice: received User:', user);
          this._user.next(user);
        })
      );
  }

  update(user: UserInfo): Observable<any> {
    return this._httpClient.patch<UserInfo>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      })
    );
  }

  
  queryUsers(userFilter: UserFilter): Subscription {
    return this._httpClient
      .post<UserInfo[]>(this._configService.formatUrl('userProfileUrl') + `${UserRestEndpoints.queryUsers}`, userFilter)
      .pipe().subscribe(
        (response: any) => {
          console.log("UserService: query Response",response);
          this.userList.next(response.items);
        }
      );
  }
}
