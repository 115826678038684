var _class, _class2, _temp;

import { ArgumentNullException, isString, ObjectBase, StaticConstructable } from '@awesome-nodes/object';
import { InjectionProvider } from "./injection/InjectionProvider";
import { isProviderConfig } from "./injection/InjectionProviderConfig";
import { InjectionScope } from "./injection/InjectionScope";
import { InjectionToken } from "./injection/InjectionToken";
import { UnknownInjectionTokenScopeException } from "./injection/InjectionTokenException";
import { convertFromProviderConfig } from "./providers/ProviderConfig";
const DEFAULT_SCOPE = 'main';

let InjectionFactory = StaticConstructable(_class = (_temp = _class2 = class InjectionFactory extends ObjectBase {
  static construct() {
    this._scopes.push(new InjectionScope(DEFAULT_SCOPE));
  }

  static get mainScope() {
    return this._scopes[0];
  }

  static get scopes() {
    return this._scopes.slice();
  }

  static locateScope(scope, parentScope) {
    if (!scope || isString(scope) && !scope.length) throw new ArgumentNullException('Can not locate empty scope names. ', 'scope');
    const s = InjectionScope.toString(scope, parentScope);
    return this._scopes.find(_scope => String(_scope) == s);
  }

  static createScope(scope, parentScope) {
    let injectionScope;
    if (!scope || !scope.length) throw new ArgumentNullException('Can not create dependency injection scope. ', 'scope');
    const foundScope = this.locateScope(parentScope ? `${parentScope}/${scope}` : scope, parentScope);

    if (!foundScope) {
      const parent = parentScope ? parentScope instanceof InjectionScope ? parentScope : this.createScope(parentScope) : parentScope === null ? undefined : this.mainScope;

      this._scopes.push(injectionScope = new InjectionScope(scope, parent));
    } else injectionScope = foundScope;

    return injectionScope;
  }

  static registerToken(token, scope = this.mainScope, parentScope) {
    const injectionScope = scope instanceof InjectionScope ? scope : this.createScope(scope, parentScope);
    return injectionScope.addToken(InjectionToken.simplify(token));
  }

  static registerProvider(token, provider = isProviderConfig(token) && token, scope = isProviderConfig(provider) && provider.scope || this.mainScope, parentScope) {
    const injectionScope = scope instanceof InjectionScope ? scope : this.createScope(scope, isProviderConfig(provider) ? null : parentScope);
    if (!provider) throw new ArgumentNullException(`Can not add provider for token '${String(token)}'. `, 'provider');
    return injectionScope.addProvider(InjectionToken.normalize(token), provider instanceof InjectionProvider ? provider : isProviderConfig(provider) ? convertFromProviderConfig(provider, injectionScope) : new (provider.bind(null, ...[injectionScope, token]))());
  }

  static resolveToken(token, scope = this.mainScope) {
    const _scope = this.locateScope(scope);

    if (!_scope) throw new UnknownInjectionTokenScopeException(scope, token, 'Injection token lookup failed. ');
    return _scope.inject(token);
  }

}, _class2._scopes = [], _temp)) || _class;

export { InjectionFactory as default };
