import { InjectionScope } from '@awesome-nodes/injection-factory';
import { EventArgs } from '@awesome-nodes/object';
export class EventDelegate {
  get eventEmitter() {
    return this._eventEmitter;
  }

  constructor(_producer) {
    this._producer = _producer;
    this._subscriptions = new Map();
    this._eventEmitter = new EVENT_EMITTER_TOKEN.value();
  }

  subscribe(observer, thisArg) {
    let next;
    let error;
    let complete;
    if (typeof observer == 'function') next = observer;else ({
      next,
      error,
      complete
    } = observer);
    if (this._subscriptions.get(next)) this.unsubscribe(next);

    const subscription = this._eventEmitter.subscribe(delegate => {
      delegate(thisArg ? next.bind(thisArg) : next);
    }, error && thisArg ? error.bind(thisArg) : error, complete && thisArg ? complete.bind(thisArg) : complete);

    subscription.add(() => {
      this._subscriptions.delete(next);
    });

    this._subscriptions.set(next, subscription);

    return subscription;
  }

  unsubscribe(observer) {
    const subscription = this._subscriptions.get(typeof observer == 'function' ? observer : observer.next);

    if (subscription && subscription.closed === false) subscription.unsubscribe();
  }

  invoke(ea = EventArgs.empty) {
    this._eventEmitter.emit((delagate, thisArg) => {
      (thisArg ? delagate.bind(thisArg) : delagate)(this._producer, ea);
    });
  }

}
export const EVENT_EMITTER_TOKEN = InjectionScope.get(EventDelegate, null).addToken('event-emitter');
