import { ArgumentNullException } from "./ArgumentNullException";
import { isString } from "./TypeChecks";
export class ObjectBase {
  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  constructor(name) {
    this._name = void 0;
    if (isString(name) && !name.length) throw new ArgumentNullException('Invalid object name. ', 'name');
    this._name = name || ObjectBase.toString(this);
  }

  toString() {
    return this._name;
  }

  equals(other) {
    if (other == this) return true;
    if (other == null) return false;
    if (!(other instanceof this.constructor)) return false;
    return this.toString() == other.toString();
  }

  static toString(object) {
    switch (typeof object) {
      case 'function':
        return object.name;

      case 'object':
        if (object) return object.constructor.name;

      default:
        return String(object);
    }
  }

}
