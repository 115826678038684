function _classPrivateFieldLooseBase(receiver, privateKey) { if (!Object.prototype.hasOwnProperty.call(receiver, privateKey)) { throw new TypeError("attempted to use private field on non-instance"); } return receiver; }

var id = 0;

function _classPrivateFieldLooseKey(name) { return "__private_" + id++ + "_" + name; }

import { ObjectBase as Base } from "./..";
import { toJSON } from "./JSON";

var _toJSON = _classPrivateFieldLooseKey("_toJSON");

export class ObjectBase extends Base {
  constructor(name) {
    super(name);
    Object.defineProperty(this, _toJSON, {
      writable: true,
      value: void 0
    });
    _classPrivateFieldLooseBase(this, _toJSON)[_toJSON] = toJSON;
  }

  toJSON(...includeProperties) {
    return _classPrivateFieldLooseBase(this, _toJSON)[_toJSON](...includeProperties);
  }

}
