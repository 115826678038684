export function getAllPropertyNames(obj) {
  const protoProps = new Map();

  do {
    const props = new Array();
    protoProps.set(obj, props);
    Object.getOwnPropertyNames(obj).forEach(prop => {
      if (!prop.endsWith('__') && props.indexOf(prop) === -1) props.push(prop);
    });
    obj = Object.getPrototypeOf(obj);
  } while (obj);

  return Array.from(protoProps.entries()).reverse().reduce((current, next) => {
    current.push(...next[1]);
    return current;
  }, new Array());
}
export function getPropertyDescriptor(obj, prop) {
  let ownPropertyDescriptor;

  do {
    ownPropertyDescriptor = Object.getOwnPropertyDescriptor(obj, prop);
    if (ownPropertyDescriptor) break;
    obj = Object.getPrototypeOf(obj);
  } while (obj);

  return ownPropertyDescriptor;
}
