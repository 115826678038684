function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { isArray } from "../..";
import { ENVIRONMENT_TOKEN } from "../EnviromentToken";
import { getAllPropertyNames, getPropertyDescriptor } from "../../reflection/getPropertyDescriptor";
export function toJSON(...includeProperties) {
  const production = ENVIRONMENT_TOKEN.value;
  includeProperties = includeProperties.filter(p => p != '');
  const jsonEntries = new Map();
  const proto = Object.getPrototypeOf(this);
  const allPropertyNames = getAllPropertyNames(proto);

  for (const key of allPropertyNames) {
    const desc = getPropertyDescriptor(proto, key);
    const hasGetter = desc && typeof desc.get === 'function';
    const includePropertyIndex = includeProperties.indexOf(key);
    const include = includePropertyIndex > -1;

    if (hasGetter && (includeProperties.length == 0 || include)) {
      const value = this[key] instanceof Map ? Array.from(this[key].values()) : this[key];
      if (value instanceof Array && !value.length || production && key == 'Name' && value == this.constructor.name) continue;
      const jsonKey = key[0].toLowerCase() + key.substring(1);
      if (include) includeProperties[includePropertyIndex] = jsonKey;
      jsonEntries.set(jsonKey, value == null ? value : typeof value == 'object' && 'toJSON' in value ? value.toJSON() : isArray(value) ? value.map(item => item && typeof item === 'object' && 'toJSON' in item ? item.toJSON() : item) : value);
    }
  }

  return includeProperties.length == 0 ? Array.from(jsonEntries.entries()).reduce((main, [key, value]) => _objectSpread(_objectSpread({}, main), {}, {
    [key]: value
  }), {}) : includeProperties.reduce((current, next) => {
    current[next] = jsonEntries.get(next);
    return current;
  }, {});
}
