import * as i0 from '@angular/core';
import { InjectionToken, Injectable, APP_INITIALIZER, NgModule, EventEmitter, Injector, Inject, Output, Input, isDevMode } from '@angular/core';
import { InjectionScope } from '@awesome-nodes/injection-factory';
import { ENVIRONMENT_TOKEN as ENVIRONMENT_TOKEN$1 } from '@awesome-nodes/object/data';
import { throwError, Observable, lastValueFrom, of, Subject, skip, catchError, firstValueFrom, map as map$1, tap as tap$1 } from 'rxjs';
import { SysType, QueryResult, EditResult } from '@yukawa/chain-base-angular-domain';
import * as i1 from '@angular/common/http';
import { HttpErrorResponse, HttpResponse, HTTP_INTERCEPTORS, HttpParams } from '@angular/common/http';
import { __decorate } from 'tslib';
import { StaticConstructable, Exception, isPrototypeOf, EventArgs, ObjectBase, ArgumentNullException } from '@awesome-nodes/object';
export * from '@awesome-nodes/object';
import { AsyncEventDelegate as AsyncEventDelegate$1, EVENT_EMITTER_TOKEN, EventDelegate as EventDelegate$1 } from '@awesome-nodes/mvvm/model';
export { ActionObserver, Disposable, ObjectModel, using } from '@awesome-nodes/mvvm/model';
import { ServiceProviderBase as ServiceProviderBase$1 } from '@awesome-nodes/mvvm/providers';
import { ServiceBase as ServiceBase$1 } from '@awesome-nodes/mvvm/services';
import { ComponentBase as ComponentBase$1 } from '@awesome-nodes/mvvm/view';
import { ViewModelBase as ViewModelBase$1 } from '@awesome-nodes/mvvm/view-model';
import { isEqualWith, fromPairs, compact, isEqual } from 'lodash-es';
import { take, delay, switchMap, map, mergeMap, concatAll, concatMap, toArray, startWith, shareReplay, tap } from 'rxjs/operators';

// noinspection JSUnusedGlobalSymbols
const setEnvironment = environment => {
  ENVIRONMENT_TOKEN$1.scope.addProvider({
    provide: ENVIRONMENT_TOKEN$1,
    useValue: environment.production
  });
  ENVIRONMENT_TOKEN.scope.addProvider({
    provide: ENVIRONMENT_TOKEN,
    useValue: environment
  });
};
const ENVIRONMENT_TOKEN = InjectionScope.get('YUKAWA', null).addToken('ENVIRONMENT_TOKEN');
const APP_ENVIRONMENT_TOKEN = new InjectionToken('APP_ENVIRONMENT_TOKEN');

// import '@yukawa/chain-base-angular-domain/chaos-sys-core';
// declare const SysType;
const TAG$1 = 'ConfigService';
class ConfigService {
  http;
  /**
   * @deprecated
   */
  configUrl = 'config.json';
  config;
  constructor(http) {
    this.http = http;
    this.config = {};
  }
  /**
   * @deprecated
   */
  initConfig() {
    return new Promise((resolve, reject) => {
      console.warn(TAG$1, 'loadConfig is deprecated use initConfigs');
      this.loadConfig().subscribe(data => {
        this.config = data;
        resolve(true);
      }, error => {
        console.error(TAG$1, `initConfig  ${error}`);
        //reject(error)
        return throwError(error || 'Server error');
      });
    });
  }
  initConfigs(locations = ['config-base.json', 'config.json'], rejectNotFound = false) {
    return new Promise((resolve, reject) => {
      for (let idx = 0; idx < locations.length; idx++) {
        let loc = locations[idx];
        console.debug('loading config at location: ', loc);
        this.http.get(loc).subscribe(cfg => {
          console.debug('got config at location: ', loc, cfg);
          this.config = SysType.objectMix(this.config || {}, cfg);
          //this.config = this.deepCopy(this.config||{}, cfg);
          if (idx == locations.length - 1) {
            resolve(this.config);
          }
        }, error => {
          console.error(TAG$1, `initConfigs  ${error}`);
          if (rejectNotFound) {
            reject(error);
            return;
          }
          if (idx == locations.length - 1) {
            resolve(this.config);
          }
        });
      }
    });
  }
  getBaseUrl(id = '') {
    return id == '' ? this.config.baseUrl : this.config[id];
  }
  getValue(id) {
    return this.config[id];
  }
  formatUrl(id = 'baseUrl') {
    let cfg = this.config;
    if (!cfg) return cfg;
    if (Object.keys(cfg).indexOf(id) === -1) throw new Error('Missing key ' + id);
    cfg = cfg[id];
    if (cfg.startsWith('/') || cfg.startsWith(':')) cfg = this.config.baseUrl + cfg;
    return cfg;
  }
  /**
   * @deprecated
   */
  getConfig(id) {
    console.warn(TAG$1, 'getConfig is deprecated use initConfigs');
    if (!id) return this.config;
    return `${this.getBaseUrl()}${this.config[id]}`;
  }
  /**
   * @deprecated
   */
  loadConfig(url = 'config.json') {
    console.warn(TAG$1, 'loadConfig is deprecated use initConfigs ' + url);
    return this.http.get(url);
  }
  /** @nocollapse */
  static ɵfac = function ConfigService_Factory(t) {
    return new (t || ConfigService)(i0.ɵɵinject(i1.HttpClient));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ConfigService,
    factory: ConfigService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
function ConfigFactory(config) {
  return () => config.initConfigs();
}
function init() {
  return {
    provide: APP_INITIALIZER,
    useFactory: ConfigFactory,
    deps: [ConfigService],
    multi: true
  };
}
const ConfigModule = {
  init: init
};
var InitService_1;
let InitService = class InitService {
  static {
    InitService_1 = this;
  }
  _configService;
  _http;
  static _configOverrides;
  static configOverridesKey = 'CONFIG_OVERRIDES';
  static configs = {
    'config.base.json': false,
    'config.json': true
  };
  _configs = new Map();
  constructor(_configService, _http) {
    this._configService = _configService;
    this._http = _http;
  }
  static get configOverrides() {
    return InitService_1._configOverrides;
  }
  get configOverrides() {
    return InitService_1._configOverrides;
  }
  static construct() {
    const item = localStorage.getItem(this.configOverridesKey);
    this._configOverrides = new Map(item ? Object.entries(JSON.parse(item)) : null);
  }
  static saveLocalStorage() {
    if (this._configOverrides.size === 0) {
      localStorage.removeItem(this.configOverridesKey);
    } else {
      localStorage.setItem(this.configOverridesKey, JSON.stringify(Object.fromEntries(InitService_1._configOverrides)));
    }
  }
  initConfigs() {
    return new Observable(subscriber => {
      this._configService.initConfigs(Object.keys(InitService_1.configs), true).then(async config => {
        console.debug('Loading config', config);
        await this.overrideConfig(config);
        console.log('Loaded config', config);
        subscriber.complete();
      }).catch(reason => {
        // reject(false);
        console.warn('Load config failed', reason);
        alert('Page is not working, please try again later.');
        setTimeout(() => {
          location.reload();
        }, 10000);
      });
    });
  }
  addConfigOverride(key, config) {
    InitService_1._configOverrides.set(key, config);
    InitService_1.saveLocalStorage();
  }
  removeConfigOverride(key) {
    InitService_1._configOverrides.delete(key);
    InitService_1.saveLocalStorage();
  }
  async overrideConfig(config) {
    if (InitService_1._configOverrides.size > 0) {
      for (const _override of InitService_1._configOverrides.keys()) {
        const configFile = InitService_1._configOverrides.get(_override);
        if (!this._configs.has(configFile)) {
          this._configs.set(configFile, await lastValueFrom(this._http.get(configFile, {})));
        }
        config[_override] = this._configs.get(configFile)[_override];
        if (_override === 'baseUrl') {
          config['sessionStoreKey'] = 'overridden';
        }
      }
    }
  }
  /** @nocollapse */
  static ɵfac = function InitService_Factory(t) {
    return new (t || InitService)(i0.ɵɵinject(ConfigService), i0.ɵɵinject(i1.HttpClient));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: InitService,
    factory: InitService.ɵfac,
    providedIn: 'root'
  });
};
InitService = InitService_1 = __decorate([StaticConstructable], InitService);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InitService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ConfigService
  }, {
    type: i1.HttpClient
  }], null);
})();
class InitModule {
  /** @nocollapse */static ɵfac = function InitModule_Factory(t) {
    return new (t || InitModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: InitModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      provide: APP_INITIALIZER,
      useFactory: myInitService => () => myInitService.initConfigs(),
      deps: [InitService, ConfigService],
      multi: true
    }]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InitModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: APP_INITIALIZER,
        useFactory: myInitService => () => myInitService.initConfigs(),
        deps: [InitService, ConfigService],
        multi: true
      }]
    }]
  }], null, null);
})();

// @Injectable({
//   providedIn: 'root'
// })
class UrlConfigService {
  cfg;
  configService;
  constructor(cfg, configService) {
    this.cfg = cfg;
    this.configService = configService;
    console.log("xxxxxxxxxxxxx");
  }
  getBackendUrl() {
    return this.configService.formatUrl(this.cfg);
  }
}
var LogTypes;
(function (LogTypes) {
  LogTypes["log"] = "log";
  LogTypes["info"] = "info";
  LogTypes["warn"] = "warn";
  LogTypes["error"] = "error";
  LogTypes["debug"] = "debug";
})(LogTypes || (LogTypes = {}));
class AsyncEventDelegate extends AsyncEventDelegate$1 {
  //noinspection JSUnusedLocalSymbols
  static eventEmitterInjectionToken = (() => {
    if (EVENT_EMITTER_TOKEN.scope.providers.length === 0) {
      EVENT_EMITTER_TOKEN.scope.addProvider({
        provide: EVENT_EMITTER_TOKEN,
        useValue: EventEmitter
      });
    }
    return EVENT_EMITTER_TOKEN;
  })();
}
class EventDelegate extends EventDelegate$1 {
  //noinspection JSUnusedLocalSymbols
  static eventEmitterInjectionToken = (() => {
    if (EVENT_EMITTER_TOKEN.scope.providers.length === 0) {
      EVENT_EMITTER_TOKEN.scope.addProvider({
        provide: EVENT_EMITTER_TOKEN,
        useValue: EventEmitter
      });
    }
    return EVENT_EMITTER_TOKEN;
  })();
}
class ServiceProviderBase extends ServiceProviderBase$1 {
  _parentInjector;
  constructor(_parentInjector) {
    super();
    this._parentInjector = _parentInjector;
  }
  inject(provider) {
    const injector = Injector.create({
      providers: [{
        provide: provider.provide,
        useFactory: provider.useFactory,
        deps: provider.deps
      }],
      parent: this._parentInjector
    });
    return injector.get(provider.provide);
  }
}

/**
 * Represents the default exception domain for services.
 */
class ServiceException extends Exception {
  _responseBody;
  constructor(message, messageTemplateValues = {}, innerException, _responseBody) {
    super(message);
    this._responseBody = _responseBody;
    ServiceException.setPrototype(this);
  }
  get responseBody() {
    return this._responseBody;
  }
}

/**
 * Represents the basic behavior for any service.
 * Accepts only one worker for a specific keyword at a time.
 */
class ServiceBase extends ServiceBase$1 {
  static handleError(e) {
    if (isPrototypeOf(e.constructor, ServiceException)) {
      console.error('Service exception thrown:', e);
    } else {
      console.error('Unhandled exception thrown:', e);
    }
  }
  /**
   * Creates a new worker instance for the specified worker name.
   *
   * @param workerName
   * @param args
   * @throws ServiceException
   */
  createWorker(workerName, ...args) {
    if (workerName == null || workerName == '') {
      throw new ServiceException('The keyword cannot be null or empty.');
    }
    if (this._backgroundWorkers.has(workerName)) {
      throw new ServiceException(`A '${workerName}' task is already in progress.`);
    }
    return super.createBackgroundWorker(this.doWork(workerName, ...args), workerName);
  }
}

/* eslint-disable @angular-eslint/contextual-lifecycle,@typescript-eslint/no-unused-expressions */
/**
 * Represents the object base for all ngx components within the model-view-view-model pattern.
 * Keep this as clean as possible from specialized code to be able to migrate to future ngx versions.
 */
class ComponentBase extends ComponentBase$1 {
  constructor(vm) {
    super(vm);
  }
  //region Public Properties
  /**
   * Returns the view-model instance associated with this component instance.
   */
  get vm() {
    return super.vm;
  }
  get model() {
    return super.model;
  }
  //eslint-disable-next-line @angular-eslint/no-input-rename
  set Model(value) {
    super.model = value;
  }
  //endregion
  //region Angular Lifecycle Handling
  /** @inheritDoc */
  ngOnInit() {
    super.vm.init && super.vm.init();
  }
  /** @inheritDoc */
  ngOnDestroy() {
    super.vm.destroy && super.vm.destroy();
  }
  /** @inheritDoc */
  ngAfterViewInit() {
    super.vm.viewInit && super.vm.viewInit();
  }
  /** @inheritDoc */
  ngAfterViewChecked() {
    super.vm.viewChecked && super.vm.viewChecked();
  }
  /** @inheritDoc */
  ngAfterContentInit() {
    super.vm.contentInit && super.vm.contentInit();
  }
  /** @inheritDoc */
  ngAfterContentChecked() {
    super.vm.contentChecked && super.vm.contentChecked();
  }
  /** @inheritDoc */
  ngOnChanges(changes) {
    super.vm.changes && super.vm.changes(changes);
  }
  /** @nocollapse */
  static ɵfac = function ComponentBase_Factory(t) {
    return new (t || ComponentBase)(i0.ɵɵinject('vm'));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ComponentBase,
    factory: ComponentBase.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComponentBase, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['vm']
    }]
  }], {
    vm: [{
      type: Output
    }],
    model: [{
      type: Output
    }],
    Model: [{
      type: Input,
      args: ['model']
    }]
  });
})();
class ViewModelBase extends ViewModelBase$1 {
  get productionMode() {
    return !!ENVIRONMENT_TOKEN$1.value;
  }
}

/**
 * Allows for retrieving singletons using `AppInjector.get(MyService)`
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
let AppInjector;
/**
 * Helper to set the exported {@link AppInjector}
 */
const setAppInjector = injector => {
  if (AppInjector) {
    // Should not happen
    console.error('Programming error: AppInjector was already set');
  } else {
    AppInjector = injector;
  }
};
class ScriptStore {
  #store = {};
  get keys() {
    return Object.keys(this.#store);
  }
  add(kind, name, url) {
    return this.#store[name] = {
      kind,
      url,
      loaded: false
    };
  }
  contains(name) {
    return this.#store.hasOwnProperty(name);
  }
  get(playerName) {
    return this.#store[playerName];
  }
}
class LoadScriptService {
  #scriptStore = new ScriptStore();
  get scriptStore() {
    return this.#scriptStore;
  }
  async loadScripts(renderer) {
    const statuses = new Array();
    for (const key of this.#scriptStore.keys) {
      statuses.push(await this.loadScript(key, renderer));
    }
    return statuses;
  }
  loadScript(name, renderer) {
    return new Promise((resolve, reject) => {
      const scriptStoreElement = this.#scriptStore.get(name);
      if (scriptStoreElement.loaded) {
        resolve({
          script: name,
          loaded: true
        });
      } else {
        const script = document.createElement('script');
        script.type = `text/${scriptStoreElement.kind}`;
        script.src = scriptStoreElement.url;
        if (script.readyState) {
          //IE Fallback
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              scriptStoreElement.loaded = true;
              resolve({
                script: name,
                loaded: true
              });
            }
          };
        } else {
          // Default
          script.onload = () => {
            scriptStoreElement.loaded = true;
            resolve({
              script: name,
              loaded: true
            });
          };
        }
        script.onerror = error => resolve({
          script: name,
          loaded: false,
          error
        });
        renderer.appendChild(document.head, script);
      }
    });
  }
}
class MetadataRecord {
  callback;
  key;
  ms;
  params;
  executedTime = 0;
  paused = false;
  startTime = new Date().getTime();
  timeSpentWaiting = 0;
  constructor(callback, key, ms, params) {
    this.callback = callback;
    this.key = key;
    this.ms = ms;
    this.params = params;
  }
}
class Timeout {
  static keyId = {};
  static keyCall = {};
  static originalMs = {};
  static metadata = {};
  /**
   * clear timeout and optionally erase all knowledge of its existence
   *
   * @param key
   * @param erase
   */
  static clear(key, erase = true) {
    clearTimeout(Timeout.keyId[key]);
    delete Timeout.keyId[key];
    delete Timeout.keyCall[key];
    if (erase) {
      delete Timeout.metadata[key];
      delete Timeout.originalMs[key];
    }
  }
  static set(...args) {
    let key;
    let ms;
    let params;
    let callback;
    if (args.length === 0) {
      throw Error('Timeout.set() requires at least one argument');
    }
    if (typeof args[1] === 'function') {
      [key, callback, ms, ...params] = args;
    } else {
      [callback, ms, ...params] = args;
      key = callback.toString();
    }
    if (!callback) {
      throw Error('Timeout.set() requires a callback parameter');
    }
    Timeout.clear(key);
    const invoke = () => {
      Timeout.metadata[key].executedTime = new Date().getTime();
      callback(...params);
    };
    Timeout.keyId[key] = setTimeout(invoke, ms || 0);
    Timeout.keyCall[key] = () => callback(...params);
    Timeout.originalMs[key] = Timeout.originalMs[key] || ms;
    Timeout.metadata[key] = new MetadataRecord(callback, key, ms, params);
    return () => Timeout.executed(key);
  }
  static create(...args) {
    if (args.length === 0) {
      throw Error('Timeout.create() requires at least one argument');
    }
    let key;
    if (typeof args[1] === 'function') {
      [key] = args;
    } else {
      const [callback] = args;
      key = callback.toString();
    }
    return Timeout.exists(key) ? false : Timeout.set(...args);
  }
  /**
   * timeout has been created
   *
   * @param key
   */
  static exists(key) {
    return key in Timeout.keyId || Timeout.metadata[key] !== undefined;
  }
  /**
   * fire the callback on demand, without affecting the timeout or meta data (execution time)
   *
   * @param key
   * @returns false if timeout does not exist or the return value of the callback
   */
  static call(key) {
    return Timeout.exists(key) && Timeout.keyCall[key]();
  }
  /**
   * test if a timeout has run
   *
   * @param key
   */
  static executed(key) {
    return Timeout.exists(key) && !!Timeout.metadata[key].executedTime;
  }
  /**
   * when timeout was last executed
   *
   * @param key
   */
  static lastExecuted(key) {
    return !Timeout.executed(key) ? null : new Date(Timeout.metadata[key].executedTime);
  }
  /**
   * timeout does exist, but has not yet run
   *
   * @param key
   */
  static pending(key) {
    return Timeout.exists(key) && !Timeout.executed(key);
  }
  /**
   * timeout does exist, but will not execute because it is paused
   *
   * @param key
   */
  static paused(key) {
    return Timeout.exists(key) && !Timeout.executed(key) && Timeout.metadata[key].paused;
  }
  /**
   * remaining time until timeout will occur
   *
   * @param key
   */
  static remaining(key) {
    if (!Timeout.metadata[key]) {
      return 0;
    }
    const metaDataRecord = Timeout.metadata[key];
    return Timeout.paused(key) ? metaDataRecord.ms - metaDataRecord.timeSpentWaiting : Math.max(0, metaDataRecord.startTime + metaDataRecord.ms - new Date().getTime());
  }
  /**
   * restart timeout with original time
   *
   * @param key
   */
  static restart(key) {
    if (!Timeout.metadata[key] || Timeout.executed(key)) {
      return false;
    }
    const metaDataRecord = Timeout.metadata[key];
    Timeout.clear(key, false);
    if (metaDataRecord.paused) {
      metaDataRecord.paused = false;
    }
    return Timeout.set(key, metaDataRecord.callback, Timeout.originalMs[key], ...metaDataRecord.params);
  }
  /**
   * pause our execution countdown until we're ready for it to resume
   *
   * @param key
   */
  static pause(key) {
    if (!Timeout.metadata[key] || Timeout.paused(key) || Timeout.executed(key)) {
      return false;
    }
    Timeout.clear(key, false);
    const metaDataRecord = Timeout.metadata[key];
    metaDataRecord.paused = true;
    metaDataRecord.timeSpentWaiting = new Date().getTime() - metaDataRecord.startTime;
    return metaDataRecord.timeSpentWaiting;
  }
  /**
   * resume paused Timeout with the remaining time
   *
   * @param key
   */
  static resume(key) {
    if (!Timeout.metadata[key] || Timeout.executed(key)) {
      return false;
    }
    const metaDataRecord = Timeout.metadata[key];
    if (!metaDataRecord.paused) {
      return false;
    }
    const originalMs = Timeout.originalMs[key];
    const remainingTime = metaDataRecord.ms - metaDataRecord.timeSpentWaiting;
    const result = Timeout.set(key, metaDataRecord.callback, remainingTime, ...metaDataRecord.params);
    Timeout.originalMs[key] = originalMs;
    return result;
  }
  /**
   * instantiate timeout to handle as object
   *
   * @param callback
   * @param ms
   * @param params
   */
  static instantiate(callback, ms = 0, ...params) {
    if (!callback) {
      throw Error('Timeout.instantiate() requires a function parameter');
    }
    const key = `${Math.random()}${callback}`.replace(/\s/g, '');
    Timeout.set(key, callback, ms, ...params);
    return {
      call: () => Timeout.call(key),
      clear: (erase = true) => Timeout.clear(key, erase),
      executed: () => Timeout.executed(key),
      exists: () => Timeout.exists(key),
      lastExecuted: () => Timeout.lastExecuted(key),
      pause: () => Timeout.pause(key),
      paused: () => Timeout.paused(key),
      pending: () => Timeout.pending(key),
      remaining: () => Timeout.remaining(key),
      restart: () => Timeout.restart(key),
      resume: () => Timeout.resume(key),
      set: (newCallback, newMs = 0, ...newParams) => Timeout.set(key, newCallback, newMs, ...newParams)
    };
  }
}

/**
 * Returns a time format like "1:01" or "4:03:59" or "123:03:59"
 *
 * @param time The time as unix timestamp.
 * @param round
 */
const timeFormat = (time, round = false) => {
  // Hours, minutes and seconds
  /* eslint-disable no-bitwise */
  const hrs = ~~(time / 3600);
  const mins = ~~(time % 3600 / 60);
  const secs = ~~time % 60;
  const numbers = new Array();
  if (hrs > 0) {
    numbers.push(hrs.toString());
  }
  if (!round || mins > 0) {
    numbers.push(hrs > 0 ? mins.toString().padStart(2, '0') : mins.toString());
  }
  if (!round || secs > 0) {
    numbers.push(!round || mins > 0 ? secs.toString().padStart(2, '0') : secs.toString());
  }
  return numbers.length > 0 ? numbers.join(':') : '0';
};
/**
 * Updates the list indexes with only updated items.
 *
 * @param list
 * @param items
 */
const updateList = (list, items) => {
  list.length = items.length;
  for (const _item of items) {
    const index = items.indexOf(_item);
    if (!isEqualWith(_item, list[index])) {
      list[index] = _item;
    }
  }
};
const enumKeys = obj => Object.keys(obj).filter(k => Number.isNaN(+k));
/**
 * Removes `undefined` object entries recursively.
 *
 * @param  obj
 */
const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) {
      obj[key] = removeEmpty(obj[key]);
    } else if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};
/**
 * Generates a random id
 *
 * @param length
 */
const randomId = (length = 10) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let name = '';
  for (let i = 0; i < length; i++) {
    name += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return name;
};
class LoggedEventArgs extends EventArgs {
  type;
  message;
  _data;
  // noinspection TypeScriptFieldCanBeMadeReadonly
  _date;
  constructor(type, message, ...data) {
    super();
    this.type = type;
    this.message = message;
    this.type = type;
    this.message = message;
    this._data = data;
    this._date = new Date();
  }
  get data() {
    return this._data;
  }
  get date() {
    return this._date;
  }
  static fromJSON(o) {
    const loggedEventArgs = new LoggedEventArgs(o.type, o.message, ...o.data);
    loggedEventArgs._date = o.date;
    return loggedEventArgs;
  }
  toJSON() {
    return {
      date: this._date,
      type: this.type,
      message: this.message,
      data: this._data
    };
  }
  toString() {
    return `${this.date.toISOString()}[${this.type}]: ${this.message}`;
  }
}

/* eslint-disable no-console */
// noinspection JSUnusedGlobalSymbols
const SESSION_STORE_KEY = 'console-logs';
class LogService extends ObjectBase {
  static #instance;
  logged = new EventDelegate(this);
  _logs = new Array();
  _trace = false;
  _consoleLogFunctions = new Map();
  constructor() {
    super('Yukawa Log Service');
    for (const _logType of enumKeys(LogTypes)) {
      this.extendLogging(_logType);
    }
    Object.defineProperty(window, 'logService', {
      get: () => LogService.instance
    });
  }
  static get instance() {
    return LogService.#instance || (LogService.#instance = new LogService());
  }
  get logs() {
    return this._logs;
  }
  get trace() {
    return this._trace;
  }
  set trace(value) {
    this._trace = value;
  }
  extendLogging(logType) {
    const logFn = console[logType];
    this._consoleLogFunctions.set(logType, logFn);
    console[logType] = (...data) => {
      const message = data.reduce((_data, next) => {
        switch (typeof next) {
          case 'object':
            if (next) {
              _data.push(next instanceof Error ? next.toString() : JSON.safeStringify(next, 4));
            }
            break;
          default:
            _data.push(String(next));
        }
        return _data;
      }, new Array()).join('\n');
      const loggedEventArgs = new LoggedEventArgs(logType, message, ...data);
      this.addLog(logFn, loggedEventArgs);
      this.saveLogs();
      this.logged.invoke(loggedEventArgs);
    };
  }
  save(type, filename) {
    const fileType = 'text/json';
    const blob = new Blob([type === 'text' ? this._logs.join('\n') : JSON.safeStringify(this._logs)], {
      type: fileType
    });
    const a = document.createElement('a');
    const url = new URL(location.href);
    const date = new Date().toISOString();
    a.download = filename ?? `${url.host.replace(':', '_')}.console-logs.${date.substring(0, date.indexOf('T'))}.${type}`;
    a.href = window.URL.createObjectURL(blob);
    a.dataset['downloadurl'] = [fileType, a.download, a.href].join(':');
    const e = new MouseEvent('click');
    a.dispatchEvent(e);
  }
  restoreLogs() {
    const storedLogs = sessionStorage.getItem(SESSION_STORE_KEY);
    if (storedLogs) {
      this._logs = JSON.parse(storedLogs).map(entry => LoggedEventArgs.fromJSON(entry));
    }
  }
  logsAvailable(...types) {
    return types.length === 0 ? this._logs.length > 0 : this._logs.some(log => types.includes(log.type));
  }
  addLog(logFn, log) {
    this._logs.push(log);
    logFn.apply(console, log.data);
    if (this._trace) {
      console.trace(log.data);
    }
  }
  saveLogs() {
    try {
      sessionStorage.setItem(SESSION_STORE_KEY, JSON.safeStringify(this._logs));
    } catch (error) {
      const quotaExceededError = error;
      let quotaExceeded = false;
      switch (quotaExceededError.code) {
        case 22:
          // storage full
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (quotaExceededError.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true;
          }
          break;
      }
      if (quotaExceeded) {
        const arrayLength = this._logs.length;
        if (arrayLength === 1) {
          const log = this._logs[0];
          this._logs[0] = LoggedEventArgs.fromJSON(log.data ? {
            date: log.date,
            type: log.type,
            message: log.message,
            data: []
          } : {
            date: log.date,
            type: log.type,
            message: log.message.substring(0, Math.floor(log.message.length / 2)),
            data: []
          });
        } else {
          this._logs = this._logs.splice(Math.floor(arrayLength / 2), arrayLength);
        }
        this.saveLogs();
        return;
      }
      if (this._consoleLogFunctions.has(LogTypes.error)) {
        this._consoleLogFunctions.get(LogTypes.error)(error);
      } else {
        console.error(error);
      }
    }
  }
}
const MOCK_API_DEFAULT_DELAY = new InjectionToken('MOCK_API_DEFAULT_DELAY');
class MockApiHandler {
  url;
  delay;
  request;
  urlParams;
  // Private
  _reply = undefined;
  _replyCount = 0;
  _replied = 0;
  /**
   * Constructor
   */
  constructor(url, delay) {
    this.url = url;
    this.delay = delay;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  /**
   * Getter for response callback
   */
  get response() {
    // If the execution limit has been reached, throw an error
    if (this._replyCount > 0 && this._replyCount <= this._replied) {
      return throwError(() => new Error('Execution limit has been reached!'));
    }
    // If the response callback has not been set, throw an error
    if (!this._reply) {
      return throwError(() => new Error('Response callback function does not exist!'));
    }
    // If the request has not been set, throw an error
    if (!this.request) {
      return throwError(() => new Error('Request does not exist!'));
    }
    // Increase the replied count
    this._replied++;
    // Execute the reply callback
    const replyResult = this._reply({
      request: this.request,
      urlParams: this.urlParams
    });
    // If the result of the reply callback is an observable...
    if (replyResult instanceof Observable) {
      // Return the result as it is
      return replyResult.pipe(take(1));
    }
    // Otherwise, return the result as an observable
    return of(replyResult).pipe(take(1));
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Reply
   *
   * @param callback
   */
  reply(callback) {
    // Store the reply
    this._reply = callback;
  }
  /**
   * Reply count
   *
   * @param count
   */
  replyCount(count) {
    // Store the reply count
    this._replyCount = count;
  }
}
class MockApiService {
  static _instance;
  _handlers = {
    'get': new Map(),
    'post': new Map(),
    'patch': new Map(),
    'delete': new Map(),
    'put': new Map(),
    'head': new Map(),
    'jsonp': new Map(),
    'options': new Map()
  };
  /**
   * Constructor
   */
  constructor() {}
  static get instance() {
    return this._instance;
  }
  static set instance(value) {
    MockApiService._instance = value;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Find the handler from the service
   * with the given method and url
   *
   * @param method
   * @param url
   */
  findHandler(method, url) {
    // Prepare the return object
    const matchingHandler = {
      handler: undefined,
      urlParams: {}
    };
    // Split the url
    const urlParts = url.split('/');
    // Get all related request handlers
    const handlers = this._handlers[method.toLowerCase()];
    // Iterate through the handlers
    handlers.forEach((handler, handlerUrl) => {
      // Skip if there is already a matching handler
      if (matchingHandler.handler) {
        return;
      }
      // Split the handler url
      const handlerUrlParts = handlerUrl.split('/');
      // Skip if the lengths of the urls we are comparing are not the same
      if (urlParts.length !== handlerUrlParts.length) {
        return;
      }
      // Compare
      const matches = handlerUrlParts.every((handlerUrlPart, index) => handlerUrlPart === urlParts[index] || handlerUrlPart.startsWith(':'));
      // If there is a match...
      if (matches) {
        // Assign the matching handler
        matchingHandler.handler = handler;
        // Extract and assign the parameters
        matchingHandler.urlParams = fromPairs(compact(handlerUrlParts.map((handlerUrlPart, index) => handlerUrlPart.startsWith(':') ? [handlerUrlPart.substring(1), urlParts[index]] : undefined)));
      }
    });
    return matchingHandler;
  }
  /**
   * Register a GET request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onGet(url, delay) {
    return this._registerHandler('get', url, delay);
  }
  /**
   * Register a POST request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onPost(url, delay) {
    return this._registerHandler('post', url, delay);
  }
  /**
   * Register a PATCH request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onPatch(url, delay) {
    return this._registerHandler('patch', url, delay);
  }
  /**
   * Register a DELETE request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onDelete(url, delay) {
    return this._registerHandler('delete', url, delay);
  }
  /**
   * Register a PUT request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onPut(url, delay) {
    return this._registerHandler('put', url, delay);
  }
  /**
   * Register HEAD request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onHead(url, delay) {
    return this._registerHandler('head', url, delay);
  }
  /**
   * Register JSONP request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onJsonp(url, delay) {
    return this._registerHandler('jsonp', url, delay);
  }
  /**
   * Register OPTIONS request handler
   *
   * @param url - URL address of the mocked API endpoint
   * @param delay - Delay of the response in milliseconds
   */
  onOptions(url, delay) {
    return this._registerHandler('options', url, delay);
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Register and return a new instance of the handler
   *
   * @param method
   * @param url
   * @param delay
   * @private
   */
  _registerHandler(method, url, delay) {
    // Create a new instance of MockApiHandler
    const hemroMockHttp = new MockApiHandler(url, delay);
    // Store the handler to access it from the interceptor
    this._handlers[method].set(url, hemroMockHttp);
    // Return the instance
    return hemroMockHttp;
  }
  /** @nocollapse */
  static ɵfac = function MockApiService_Factory(t) {
    return new (t || MockApiService)();
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MockApiService,
    factory: MockApiService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MockApiService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const mock = apiHandler => (target, propertyKey, descriptor) => {
  if (descriptor === undefined) {
    descriptor = Object.getOwnPropertyDescriptor(target, propertyKey);
  }
  const originalMethod = descriptor.value;
  // override the descriptor/value parameter
  descriptor.value = function (...args) {
    const register = (environments, handler) => {
      let handle = false;
      for (const _env of environments) {
        switch (_env) {
          case 'design':
            if (ENVIRONMENT_TOKEN.value.production == null) {
              handle = true;
            }
            break;
          case 'development':
            if (ENVIRONMENT_TOKEN.value.production === false) {
              handle = true;
            }
            break;
          case 'production':
            if (ENVIRONMENT_TOKEN.value.production === true) {
              handle = true;
            }
            break;
        }
      }
      if (handle) {
        handler(MockApiService.instance, ...args);
      }
    };
    apiHandler.apply(this, [register.bind(this)]);
    return originalMethod.apply(this, args);
  };
  // return edited descriptor as opposed to overwriting the descriptor
  return descriptor;
};
class MockApiInterceptor {
  _defaultDelay;
  _hemroMockApiService;
  /**
   * Constructor
   */
  constructor(_defaultDelay, _hemroMockApiService) {
    this._defaultDelay = _defaultDelay;
    this._hemroMockApiService = _hemroMockApiService;
  }
  /**
   * Intercept
   *
   * @param request
   * @param next
   */
  intercept(request, next) {
    // Try to get the request handler
    const {
      handler,
      urlParams
    } = this._hemroMockApiService.findHandler(request.method.toUpperCase(), request.url);
    // Pass through if the request handler does not exist
    if (!handler) {
      return next.handle(request);
    }
    // Set the intercepted request on the handler
    handler.request = request;
    // Set the url params on the handler
    handler.urlParams = urlParams;
    // Subscribe to the response function observable
    return handler.response.pipe(delay(handler.delay ?? this._defaultDelay ?? 0), switchMap(response => {
      // If there is no response data,
      // throw an error response
      if (!response) {
        response = new HttpErrorResponse({
          error: 'NOT FOUND',
          status: 404,
          statusText: 'NOT FOUND'
        });
        return throwError(response);
      }
      // Parse the response data
      const data = {
        status: response[0],
        body: response[1]
      };
      // If the status code is in between 200 and 300,
      // return a success response
      if (data.status >= 200 && data.status < 300) {
        response = new HttpResponse({
          body: data.body,
          status: data.status,
          statusText: 'OK'
        });
        return of(response);
      }
      // For other status codes,
      // throw an error response
      response = new HttpErrorResponse({
        error: data.body.error,
        status: data.status,
        statusText: 'ERROR'
      });
      return throwError(response);
    }));
  }
  /** @nocollapse */
  static ɵfac = function MockApiInterceptor_Factory(t) {
    return new (t || MockApiInterceptor)(i0.ɵɵinject(MOCK_API_DEFAULT_DELAY), i0.ɵɵinject(MockApiService));
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MockApiInterceptor,
    factory: MockApiInterceptor.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MockApiInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MOCK_API_DEFAULT_DELAY]
    }]
  }, {
    type: MockApiService
  }], null);
})();
class MockApiModule {
  constructor(mockService) {
    MockApiService.instance = mockService;
  }
  /**
   * MockApi module default configuration.
   *
   * @param mockApiServices - Array of services that register mock API handlers
   * @param config - Configuration options
   * @param config.delay - Default delay value in milliseconds to apply all responses
   */
  static forRoot(mockApiServices, config) {
    return {
      ngModule: MockApiModule,
      providers: [{
        provide: APP_INITIALIZER,
        deps: [...mockApiServices],
        useFactory: () => () => null,
        multi: true
      }, {
        provide: MOCK_API_DEFAULT_DELAY,
        useValue: config?.delay ?? 0
      }]
    };
  }
  /** @nocollapse */
  static ɵfac = function MockApiModule_Factory(t) {
    return new (t || MockApiModule)(i0.ɵɵinject(MockApiService));
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MockApiModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: MockApiInterceptor,
      multi: true
    }]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MockApiModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: MockApiInterceptor,
        multi: true
      }]
    }]
  }], () => [{
    type: MockApiService
  }], null);
})();
class MockApiUtils {
  /**
   * Constructor
   */
  constructor() {}
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Generate a globally unique id
   */
  static guid() {
    /* eslint-disable */
    let d = new Date().getTime();
    // Use high-precision timer if available
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    /* eslint-enable */
  }
}

// noinspection DuplicatedCode
/**
 * ts impl of inc.yukawa.chain.base.rest.ctrl.RepoAspectRest
 */
class RepoAspectRest {
  http;
  configService;
  urlKey;
  constructor(http, configService, urlKey = 'baseUrl') {
    this.http = http;
    this.configService = configService;
    this.urlKey = urlKey;
  }
  // noinspection JSMethodCanBeStatic
  get verbose() {
    return isDevMode();
  }
  formatServiceUrl(path = '') {
    return this.configService.formatUrl(this.urlKey) + path;
  }
  // -----------------------------------------------------------------------------------------------------------------
  load(key) {
    const queryParamsList = [];
    if (key !== undefined && key !== null) {
      queryParamsList.push({
        name: 'key',
        value: key.toString()
      });
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    return this.http.get(this.formatServiceUrl('/'), {
      params
    });
  }
  count(filter) {
    return this.http.post(this.formatServiceUrl('/count'), filter);
  }
  find(filter) {
    return this.http.post(this.formatServiceUrl('/find'), filter);
  }
  findKeys(filter) {
    return this.http.post(this.formatServiceUrl('/findKeys'), filter);
  }
  query(filter) {
    return this.http.post(this.formatServiceUrl('/query'), filter).pipe(map(data => QueryResult.fromData(data)));
  }
  // -----------------------------------------------------------------------------------------------------------------
  put(entity) {
    return this.http.post(this.formatServiceUrl('/put'), entity);
  }
  create(entity) {
    return this.http.post(this.formatServiceUrl('/create'), entity);
  }
  update(entity) {
    return this.http.post(this.formatServiceUrl('/update'), entity);
  }
  merge(entity) {
    return this.http.post(this.formatServiceUrl('/merge'), entity);
  }
  edit(entity) {
    throw new Error('edit not implemented');
  }
  // -----------------------------------------------------------------------------------------------------------------
  deleteByKey(key) {
    const queryParamsList = [];
    if (key !== undefined && key !== null) {
      queryParamsList.push({
        name: 'key',
        value: key.toString()
      });
    }
    let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
    return this.http.delete(this.formatServiceUrl('/'), {
      params
    });
  }
  delete(entity) {
    return this.http.post(this.formatServiceUrl('/delete'), entity);
  }
  deleteAll(filter) {
    return this.http.post(this.formatServiceUrl('/deleteAll'), filter);
  }
}
class InterceptedRepoAspectsRest extends RepoAspectRest {
  constructor(http, configService, urlKey) {
    super(http, configService, urlKey);
  }
  load(key) {
    return this.beforeLoad(key).pipe(mergeMap(key => super.load(key))).pipe(mergeMap(entity => this.afterLoad(entity)));
  }
  find(filter) {
    return this.beforeRead(filter).pipe(mergeMap(filter => super.find(filter))).pipe(concatAll()).pipe(concatMap(res => this.afterRead(res))).pipe(toArray());
  }
  query(filter) {
    return this.beforeRead(filter).pipe(mergeMap(filter => super.query(filter))).pipe(mergeMap(res => this.afterQuery(res)));
  }
  create(entity) {
    return this.beforeWrite(entity).pipe(mergeMap(entity => super.create(entity))).pipe(mergeMap(entity => this.afterWrite(entity)));
  }
  update(entity) {
    return this.beforeWrite(entity).pipe(mergeMap(entity => super.update(entity))).pipe(mergeMap(entity => this.afterWrite(entity)));
  }
  merge(entity) {
    return this.beforeWrite(entity).pipe(mergeMap(entity => super.merge(entity))).pipe(mergeMap(entity => this.afterWrite(entity)));
  }
  put(entity) {
    return this.beforeWrite(entity).pipe(mergeMap(entity => super.put(entity))).pipe(mergeMap(entity => this.afterWrite(entity)));
  }
  deleteByKey(key) {
    return this.beforeDelete(key).pipe(mergeMap(key => super.deleteByKey(key))).pipe(mergeMap(entity => this.afterDelete(entity)));
  }
  beforeLoad(key) {
    return of(key);
  }
  beforeRead(filter) {
    return of(filter);
  }
  beforeWrite(entity) {
    return of(entity);
  }
  beforeDelete(key) {
    return of(key);
  }
  afterLoad(entity) {
    return of(entity);
  }
  afterRead(entity) {
    return of(entity);
  }
  afterQuery(result) {
    return of(result);
  }
  afterWrite(entity) {
    return of(entity);
  }
  afterDelete(entity) {
    return of(entity);
  }
}
class CachedRepoAspectRest extends InterceptedRepoAspectsRest {
  cacheDelay;
  _filterCache = new Map();
  _idCache = new Map();
  _idCacheRefreshSubjects = new Map();
  _keyExtractor;
  constructor(http, configService, urlKey, keyExtractor, cacheDelay = 1500) {
    super(http, configService, urlKey);
    this.cacheDelay = cacheDelay;
    this._keyExtractor = keyExtractor;
  }
  query(filter) {
    const cacheKey = this.findQueryCacheKey(filter);
    if (!this._filterCache.has(cacheKey)) {
      const subject = new Subject();
      this._filterCache.set(filter, {
        subject: subject,
        observable: subject.pipe(delay(this.cacheDelay)).pipe(startWith('')).pipe(switchMap(() => super.query(filter))).pipe(shareReplay(1))
      });
    }
    return this._filterCache.get(cacheKey)?.observable;
  }
  load(key) {
    if (!this.getRefreshSubject(key)) {
      this._idCacheRefreshSubjects.set(key, new Subject());
    }
    if (!this.getCachedEntityObservable(key)) {
      this._idCache.set(key, this.getRefreshSubject(key).pipe(delay(this.cacheDelay)).pipe(startWith('')).pipe(switchMap(() => super.load(key))).pipe(shareReplay(1)));
    }
    return this.getCachedEntityObservable(key);
  }
  update(entity) {
    return super.update(entity).pipe(this.refreshOp(entity));
  }
  merge(entity) {
    return super.merge(entity).pipe(this.refreshOp(entity));
  }
  put(entity) {
    return super.put(entity).pipe(this.refreshOp(entity));
  }
  edit(entity) {
    return super.edit(entity).pipe(this.refreshOp(entity));
  }
  refreshOp(entity) {
    return source => source.pipe(switchMap(async value => {
      await this.refreshCaches(entity);
      return value;
    }));
  }
  async refreshCaches(entity) {
    await this.refreshEntityCache(this._keyExtractor(entity));
    await this.refreshQueryCache(entity);
  }
  refresh() {}
  getCachedEntityObservable(key) {
    return this._idCache.get(key);
  }
  getRefreshSubject(key) {
    return this._idCacheRefreshSubjects.get(key);
  }
  async refreshEntityCache(key) {
    const refreshSubject = this.getRefreshSubject(key);
    if (refreshSubject) {
      return new Promise((resolve, reject) => {
        const subscription = this.getCachedEntityObservable(key).pipe(skip(1), tap(() => {
          resolve();
          subscription.unsubscribe();
        }), catchError(error => {
          reject(error);
          return throwError(error);
        })).subscribe();
        refreshSubject.next();
      });
    }
  }
  async refreshQueryCache(filter) {
    if (this._keyExtractor(filter)) {
      await Promise.all(Array.from(this._filterCache.values()).map(async (cache, list) => {
        const queryResult = await firstValueFrom(cache.observable);
        if (queryResult.items.find(entity => this._keyExtractor(entity) === this._keyExtractor(filter))) {
          await this.refreshCache(cache);
        }
      }));
    } else {
      if (filter) {
        const queryCacheKey = this.findQueryCacheKey(filter);
        const cache = this._filterCache.get(queryCacheKey);
        if (cache) {
          return this.refreshCache(cache);
        }
      } else {
        const promises = [];
        this._filterCache.forEach((cache, list) => {
          promises.push(this.refreshCache(cache));
        });
        await Promise.all(promises);
      }
    }
  }
  removeQueryCache(filter) {
    const key = this.findQueryCacheKey(filter);
    const queryCacheKey = this._filterCache.get(key);
    if (queryCacheKey) {
      queryCacheKey.subject.complete();
      this._filterCache.delete(key);
      return true;
    }
    return false;
  }
  clearQueryCache() {
    this._filterCache.forEach((cache, list) => {
      cache.subject.complete();
    });
    this._filterCache.clear();
  }
  clearIdCache() {
    this._idCache.clear();
    Object.keys(this._idCacheRefreshSubjects).forEach(key => {
      this.getRefreshSubject(key).complete();
    });
    this._idCacheRefreshSubjects.clear();
  }
  findQueryCacheKey(filter) {
    return Array.from(this._filterCache.keys()).find(_filter => isEqual(_filter, filter)) || filter;
  }
  refreshCache(cache) {
    return new Promise((resolve, reject) => {
      const subscription = cache.observable.pipe(skip(1), tap(() => {
        resolve();
        subscription.unsubscribe();
      }), catchError(error => {
        reject(error);
        return throwError(() => error);
      })).subscribe();
      cache.subject.next();
    });
  }
}

/**
 * Entity class is used to represent an object within the main application model. This
 * entity can be transferred to/from the server, and there are functions to assist with
 * copying the object to a data transfer format to send to the server, and functions to
 * assist with updating the object based upon responses from the server.
 *
 * When implementing an Entity you need to:
 * 1. Override @method toJson to convert the entity to json. This can use
 *    method {@link toJsonWithKeys} to indicate the values to copy.
 * 2. Override @method updateFromJson to update the entity with data from a json object.
 *    This is the inverse of @method toJson and can be @method setFromJson.
 * 3. Override @method key to indicate a unique value used to cache the entity
 *    (if/when cached)
 * 4. Implement a EntityService to handle transmission of this object to the server
 *    for CRUD operations.
 */
class Entity {
  /**
   * Construct an Entity object
   *
   * @param initialData An optional object storing the data to initialise the Entity with, calls @method updateFromJson with the data.
   */
  constructor(initialData) {
    if (initialData) {
      this.updateFromJson(initialData);
    }
  }
  /**
   * Update the entity with data from the passed in json object. This is used when updated
   * details are fetched from the server. This method takes care of copying data by key
   * from the json data to the entity itself.
   *
   * @param data  the new data to be stored within the entity
   * @param keys  the keys of the data to map
   * @param ignoredKeys
   * @param maps  an optional map of functions that are called to translate
   *              specific values from the json where a straight data copy is not
   *              appropriate/possible.
   */
  setFromJson(data, keys, ignoredKeys, maps) {
    keys.forEach(key => {
      if (maps && maps[key]) {
        const map = maps[key];
        this[key] = typeof map === 'function' ? map(data[key]) : map;
      } else if (ignoredKeys && ignoredKeys.indexOf(key) < 0 || !ignoredKeys) {
        this[key] = data[key];
      }
    });
  }
  /**
   * Copy the data within the entity into a json object and return. This is used when
   * data needs to be copied from the entity and sent to the server. Data is copied from
   * the entity for each of the @param keys which are directly copied from the entity
   * into the json. Where data cannot be directly copied, the @param maps can be
   * used to provide key based mapping functions to translate the data.
   *
   * @param keys  an optional map of functions that are called to translate
   *              specific values from the entity where a straight data copy is not
   *              appropriate/possible.
   * @param maps
   */
  toJsonWithKeys(keys, maps) {
    const json = {};
    keys.forEach(key => {
      if (maps && maps[key]) {
        json[key] = maps[key](key);
      } else {
        json[key] = this[key];
      }
    });
    return json;
  }
}
class EntityCache {
  globalCache = new Map();
  _store = this.globalCache;
  get store() {
    return this._store;
  }
  /**
   * This allows you to specify the source of a cache, which may change from a global source to
   * a cache contained within another entity (for example, a cache of comments in a video).
   *
   * @param value  the new source to be used for the cache. The global cache will be used if
   *                this is null or undefined.
   */
  set store(value) {
    if (value) {
      this._store = value;
    } else {
      this._store = this.globalCache;
    }
  }
  /**
   * Checks if an entity exists for a given key within the current cache.
   *
   * @param key Key of entity to check for.
   * @returns true if the entity with key is in the cache
   */
  has(key) {
    return this._store.has(key);
  }
  /**
   * Store an entity in the cache
   *
   * @param key key of the entity to store
   * @param entity the entity object to store in the cache
   */
  add(key, entity) {
    this._store.set(key, entity);
  }
  /**
   * Read a given entity from the cache.
   *
   * @param key key of entity to read from cache
   */
  get(key) {
    return this._store.get(key);
  }
  /**
   * Returns an entity for the given key.
   *
   * @param key key of entity to store in cache
   * @returns `true` if an element in the Map object existed and has been removed, or `false` if the element does not exist.
   */
  remove(key) {
    return this._store.delete(key);
  }
  /**
   * Removes all elements from the cache.
   */
  clear() {
    this._store.clear();
  }
}
class EntityRepository extends EntityCache {
  _repositoryService;
  constructor(_repositoryService) {
    super();
    this._repositoryService = _repositoryService;
  }
  static isQueryResult(resultOrQuery) {
    return resultOrQuery != null && Array.isArray(resultOrQuery['items']);
  }
  add(observable, entity) {
    if (typeof observable === 'string') {
      if (entity == null) {
        throw new ArgumentNullException('', 'entity');
      }
      return super.add(observable, entity);
    }
    let result = observable.pipe(map$1(response => {
      if (EntityRepository.isQueryResult(response)) {
        response.items = this.map(response.items);
        return response;
      }
      if (Array.isArray(response)) {
        return this.map(response);
      }
      if (entity != null) {
        entity.updateFromJson(response);
        return entity;
      }
      return this._repositoryService.createInstanceFrom(response);
    }));
    if (this._repositoryService.keyForEntityCache) {
      result = result.pipe(tap(_entity => {
        if (EntityRepository.isQueryResult(_entity)) {
          _entity.items.forEach(item => super.add(item.key, item));
        } else if (Array.isArray(_entity)) {
          _entity.forEach(item => super.add(item.key, item));
        } else if (_entity) {
          super.add(_entity.key, _entity);
        }
      }));
    }
    return result;
  }
  get(observable, key) {
    if (typeof observable === 'number' || typeof observable === 'string') {
      return super.get(String(observable));
    }
    if (observable instanceof Entity) {
      return super.get(observable.key);
    }
    if (key == null || key === '') {
      throw new ArgumentNullException('', 'key');
    }
    if (this.has(key)) {
      return of(super.get(key));
    }
    let result = observable;
    if (this._repositoryService.keyForEntityCache != null) {
      result = this.add(observable);
    }
    return result;
  }
  update(observable, entity) {
    let result = observable.pipe(map$1(rawData => {
      entity?.updateFromJson(rawData);
      return entity;
    }));
    if (this._repositoryService.keyForEntityCache != null) {
      result = result.pipe(tap(updatedEntity => super.add(updatedEntity.key, updatedEntity)));
    }
    return result;
  }
  remove(observable, entity) {
    if (typeof observable === 'number' || typeof observable === 'string') {
      return super.remove(String(observable));
    }
    if (observable instanceof Entity) {
      return super.remove(observable.key);
    }
    let result = observable.pipe(map$1(response => {
      if (Array.isArray(response)) {
        return this.map(response);
      } else if (entity != null) {
        entity.updateFromJson(response);
        return entity;
      } else {
        return this._repositoryService.createInstanceFrom(response, entity);
      }
    }));
    if (this._repositoryService.keyForEntityCache != null) {
      result = result.pipe(map$1(response => {
        if (Array.isArray(response)) {
          response.forEach(_entity => super.remove(_entity.key));
        } else {
          super.remove(response.key);
        }
        return response;
      }));
    }
    return result;
  }
  /**
   * Instantiates an array of elements as objects from the provided JSON.
   *
   * @returns The array of mapped entity objects
   */
  map(collection, other) {
    return collection.map(data => this._repositoryService.createInstanceFrom(data, other));
  }
}
class CachedRepoEntityService extends CachedRepoAspectRest {
  repository = new EntityRepository({
    createInstanceFrom: this.createInstanceFrom.bind(this),
    keyForEntityCache: this.keyForEntityCache ? this.keyForEntityCache.bind(this) : this.keyForEntityCache
  });
  //region RepoAspectRest Overloads
  load(key) {
    return this.repository.add(super.load(key));
  }
  find(filter) {
    return this.repository.add(super.find(filter));
  }
  query(filter) {
    return this.repository.add(super.query(filter));
  }
  put(entity) {
    return this.repository.add(super.put(entity.toJson()), entity);
  }
  create(entity) {
    return this.repository.add(super.create(entity.toJson()), entity);
  }
  update(entity) {
    return this.repository.update(super.update(entity.toJson()), entity);
  }
  merge(entity) {
    return this.repository.update(super.merge(entity.toJson()), entity);
  }
  edit(entity) {
    return this.repository.update(super.edit(entity.toJson()), entity);
  }
  deleteByKey(key) {
    return this.repository.remove(super.deleteByKey(key), this.repository.get(key));
  }
  delete(entity) {
    return this.repository.remove(super.delete(entity.toJson()), entity);
  }
  deleteAll(filter) {
    return this.repository.remove(super.deleteAll(filter)).pipe(tap$1(entities => {
      this.removeQueryCache(filter);
    }));
  }
  //endregion
  all() {
    return this.repository.add(this.http.get(this.formatServiceUrl('/all'), {}));
  }
  add(key) {
    return this.repository.add(this.http.post(this.formatServiceUrl('/add'), key));
  }
  remove(key) {
    return this.repository.remove(this.http.post(this.formatServiceUrl('/remove'), key), this.repository.get(key));
  }
  //region IEntityRepositoryService Members
  clearEntityCache() {
    this.repository.clear();
    this.clearIdCache();
    this.clearQueryCache();
  }
}
const TAG = 'RestAspect';
class RestAspect {
  http;
  configService;
  serviceUrl;
  constructor(http, configService, serviceUrl) {
    this.http = http;
    this.configService = configService;
    this.serviceUrl = serviceUrl;
  }
  formatBaseUrl(path = '') {
    return this.configService.getBaseUrl('baseUrl') + path;
  }
  formatServiceUrl(path = '') {
    return this.serviceUrl + path;
  }
  // noinspection JSMethodCanBeStatic
  get verbose() {
    return isDevMode();
  }
  search(url, filter, method = 'post') {
    let m = method || 'post';
    if (this.verbose) console.debug(TAG, "search:", m, url, filter);
    return this.http[m](url, filter).pipe(map(r => {
      let result = {
        took: r.took,
        timedOut: r.timed_out,
        rows: []
      };
      let h = r.hits;
      if (h) {
        result.total = h.total;
        for (let idx = 0; idx < h.hits.length; idx++) {
          const hit = h.hits[idx];
          let source = hit._source;
          // console.log('DeviceService', hit);
          result.rows.push(source);
        }
      }
      return result;
    }));
  }
  query(url, filter, options = {}) {
    //options = this.defaultOptions(options);
    if (this.verbose) console.debug(TAG, "query:", url, filter);
    return this.http.post(url, filter, options).pipe(map(data => QueryResult.fromData(data)));
  }
  edit(url, value = null, options = {}) {
    if (this.verbose) console.debug(TAG, "edit:", url, value);
    return (options.method === 'put' ? this.http.put(url, value, options) : this.http.post(url, value, options)).pipe(map(data => EditResult.fromData(data)));
  }
  load(url, value = null, options = {}) {
    if (this.verbose) console.debug(TAG, "load:", url);
    let method = options.method || 'get';
    let obs;
    if (value) {
      obs = this.http[method](url, value, options);
    } else {
      obs = this.http[method](url, options);
    }
    return obs;
  }
  download(url, filter = null, options = {}) {
    if (this.verbose) console.debug(TAG, "download:", url, filter);
    options = options || {};
    options.responseType = options.responseType || 'blob';
    let method = options.method || 'post';
    return this.http[method](url, filter, options);
  }
  checkNotNull(value, message = "Not found") {
    if (value == null) throw new Error(message);
    return value;
  }
}

/**
 * ResourceService, responsible for the CRUD actions for all resources which inherit form it.
 * This is used to interact with the server, and create Entity objects that are returned for
 * use within the application.
 */
class RestEntityService extends RestAspect {
  _configService;
  constructor(http, _configService, serviceUrl) {
    super(http, _configService, serviceUrl);
    this._configService = _configService;
  }
  get serverKey() {
    return this.entityName.replace(/(.)([A-Z][a-z]+)/, '$1_$2').replace(/([a-z0-9])([A-Z])/, '$1_$2').toLowerCase();
  }
  get(pathIds, other, options) {
    const object = {
      ...pathIds
    };
    if (typeof pathIds === 'number') {
      object['id'] = pathIds;
    }
    const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);
    return this.http.get(path, options).pipe(map(rawData => this.createInstanceFrom(rawData, other))); // Turn
    // the raw JSON returned into the object T
  }
  query(pathIds, other, options) {
    let observable;
    if (typeof pathIds === 'string') {
      observable = super.query(pathIds, other || {}, options);
    } else {
      const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, pathIds);
      observable = this.http.post(path, options);
    }
    return observable.pipe(map(rawData => {
      rawData.items = this.convertCollection(rawData.items, other);
      return rawData;
    }));
  }
  update(pathIds, obj, options) {
    if (obj === undefined) {
      obj = pathIds;
    }
    // need to pass object through as path id and form data
    return this.put(pathIds, obj.toJson(), options).pipe(map(rawData => {
      obj?.updateFromJson(rawData);
      return obj;
    }));
  }
  put(pathIds, data, options) {
    const object = {
      ...pathIds
    };
    const json = data ? data : typeof pathIds.toJson === 'function' ? pathIds.toJson() : pathIds;
    const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);
    return this.http.put(path, json, options);
  }
  create(pathIds, data, other, options) {
    const object = {
      ...pathIds
    };
    const json = data ? data : typeof pathIds.toJson === 'function' ? pathIds.toJson() : pathIds;
    const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);
    return this.http.post(path, json, options).pipe(map(rawData => this.createInstanceFrom(rawData, other)));
  }
  delete(pathIds, options) {
    const object = {
      ...pathIds
    };
    if (typeof pathIds === 'number') {
      object['id'] = pathIds;
    }
    const path = this.buildEndpoint(options?.alternateEndpointFormat || this.endpointFormat, object);
    return this.http.delete(path, options);
  }
  /**
   * Helper function to convert end point format strings to final path
   *
   * @param path the end point format string with id placeholders
   * @param params the object to get id values from for the placeholder.
   *
   * @returns The endpoint.
   */
  buildEndpoint(path, params) {
    // Replace any keys with provided values
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          // If the key is undefined, just replace with an empty string.
          path = path.replace(`:${key}:`, params[key] ? params[key] : '');
        }
      }
    }
    // Strip any missed keys
    path = path.replace(/:[\w-]*?:/, '');
    return `${this.serviceUrl}/${path}`;
  }
  /**
   * Instantiates an array of elements as objects from the JSON returned
   * from the server.
   *
   * @returns The array of Objects
   */
  convertCollection(collection, other) {
    return collection.map(data => this.createInstanceFrom(data, other));
  }
}

/**
 * The CachedEntityService provides wraps the EntityService and provides a cache that stores
 * previously fetched entity objects from the server. Objects within the cache are updated when
 * new values are fetched from the server.
 */
class CachedRestEntityService extends RestEntityService {
  cache = new EntityCache();
  /**
   * This function is used to map the path ids to a unique key to locate the associated entity
   * within the cache.
   *
   * @param pathIds the pathIds used to determine the key
   * @returns       a unique key to identify the associated entity
   */
  static keyFromPathIds(pathIds) {
    if (typeof pathIds === 'object') {
      if (pathIds instanceof Entity) {
        return pathIds.key;
      }
      return pathIds.hasOwnProperty('id') ? pathIds['id'].toString() : '';
    } else if (typeof pathIds === 'number') {
      return pathIds.toString();
    } else {
      return pathIds;
    }
  }
  update(pathIds, obj, options) {
    return super.update(pathIds, obj, options).pipe(tap(updatedEntity => this.cache.add(updatedEntity.key, updatedEntity)));
  }
  query(pathIds, other, options) {
    return super.query(pathIds, other, options).pipe(tap(entityList => {
      entityList.items.forEach(entity => {
        this.cache.add(entity.key, entity);
      });
    }));
  }
  fetch(pathIds, other, options) {
    const key = CachedRestEntityService.keyFromPathIds(pathIds);
    return this.get(pathIds, other, options).pipe(map(entity => {
      if (this.cache.has(key)) {
        const cachedEntity = this.cache.get(key);
        Object.assign(cachedEntity, entity);
        return cachedEntity;
      } else {
        this.cache.add(key, entity);
        return entity;
      }
    }));
  }
  get(pathIds, other, options) {
    const key = CachedRestEntityService.keyFromPathIds(pathIds);
    if (this.cache.has(key)) {
      return of(this.cache.get(key));
    } else {
      return super.get(pathIds, other, options).pipe(tap(entity => this.cache.add(entity.key, entity)));
    }
  }
  /**
   * Make a create request to the endpoint, using the supplied parameters to determine the path.
   * The results of the request are cached using the key of the entity.
   *
   * @param pathIds An object with keys which match the placeholders within the endpointFormat string.
   * @param data    A FormData or object with the values to pass up in the body of the update/put request.
   * @param other   Any other data needed to be passed to the entity on creation
   * @param options Optional http options
   * @returns a new cold observable with the newly created @type {T}
   */
  create(pathIds, data, other, options) {
    return super.create(pathIds, data, other, options).pipe(tap(entity => this.cache.add(entity.key, entity)));
  }
  delete(pathIds, options) {
    const key = CachedRestEntityService.keyFromPathIds(pathIds);
    const cache = this.cache;
    return super.delete(pathIds, options).pipe(
    // Tap performs a side effect on Observable, but return it identical to the source.
    tap(response => {
      cache.remove(key);
    }));
  }
}

/*
 * Public API Surface of chain-base-angular-client
 */
//import 'chaos-sys-core-1.9.3-SNAPSHOT.min.js';
//export var SysType:  any;

/**
 * Generated bundle index. Do not edit.
 */

export { APP_ENVIRONMENT_TOKEN, AppInjector, AsyncEventDelegate, CachedRepoAspectRest, CachedRepoEntityService, CachedRestEntityService, ComponentBase, ConfigFactory, ConfigModule, ConfigService, ENVIRONMENT_TOKEN, Entity, EntityCache, EntityRepository, EventDelegate, InitModule, InitService, InterceptedRepoAspectsRest, LoadScriptService, LogService, LogTypes, LoggedEventArgs, MOCK_API_DEFAULT_DELAY, MockApiModule, MockApiService, MockApiUtils, RepoAspectRest, RestAspect, RestEntityService, ServiceBase, ServiceException, ServiceProviderBase, Timeout, UrlConfigService, ViewModelBase, enumKeys, init, mock, randomId, removeEmpty, setAppInjector, setEnvironment, timeFormat, updateList };
