import { KeyValuePair } from "../collections/KeyValuePair";
import { Delegate, EventArgs } from "./Delegate";
import { Observable, share, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
export class HandlerSubscription extends KeyValuePair {
  get handler() {
    return this.key;
  }

  get subscription() {
    return this.value;
  }

}
export class MulticastDelegate extends Delegate {
  constructor(...args) {
    super(...args);
    this._handlers = [];
  }

  get handlers() {
    return this._handlers;
  }

  subscribe(handler, thisArg) {
    if (thisArg != null) handler = handler.bind(thisArg);

    if (this._observable == null) {
      const observable = new Observable(observer => {
        this._observer = observer;
        return () => {
          this._observable = undefined;
          this._subscription = undefined;
          this._observer = undefined;
        };
      });
      this._observable = MulticastDelegate.multicast(observable);
    }

    const subscription = this._observable.subscribe(eventArgs => {
      handler(this._target, eventArgs ? eventArgs : EventArgs.empty);
    });

    subscription.add(() => {
      this.unsubscribe(handler);
    });
    const handlerSubscription = new HandlerSubscription(handler, subscription);

    this._handlers.push(handlerSubscription);

    return handlerSubscription.subscription;
  }

  unsubscribe(handler) {
    this._handlers = this._handlers.filter(subscriber => {
      const match = subscriber.handler === handler;
      if (match && !subscriber.subscription.closed) subscriber.subscription.unsubscribe();
      return !match;
    });
  }

  static multicast(observable) {
    return observable.pipe(catchError(err => throwError(() => err)), share({
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: false
    }));
  }

}
