import { ArgumentException } from "./ArgumentException";
import { ArgumentNullException } from "./ArgumentNullException";
import { ObjectBase } from "./ObjectBase";
import { isNumber, isString } from "./TypeChecks";
export class Enum extends ObjectBase {
  get value() {
    return this._value;
  }

  constructor(_value, name) {
    super(name);
    this._value = _value;
    Enum.checkValue(_value);
  }

  toString() {
    return this.name != ObjectBase.toString(this) ? this.name : String(this._value);
  }

  static checkValue(value) {
    const type = value != null ? typeof value : String(value);

    switch (type) {
      case 'boolean':
      case 'symbol':
        return true;

      default:
        if (isNumber(value) || isString(value) && value.length) return true;
        if (isString(value) && !value.length || value == 'null' || value == 'undefined') throw new ArgumentNullException('Enum members must have a value. ', 'value');
        throw new ArgumentException(`The provided enum value of type '${type}' for enum '${ObjectBase.toString(this)}' is invalid. Only the types 'boolean', 'number', 'string' and 'symbol' are allowed.`, 'value');
    }
  }

  static enum(value, name) {
    this.checkValue(value);
    const instance = new this(value, name);
    Object.defineProperty(this, typeof instance._value == 'boolean' ? Number(instance._value) : instance._value, {
      get: () => {
        return instance;
      },
      set: () => {},
      enumerable: true,
      configurable: true
    });
    return instance;
  }

  static value(value) {
    this.checkValue(value);
    Object.defineProperty(this, String(value), {
      get: () => {
        return value;
      },
      set: () => {},
      enumerable: true,
      configurable: true
    });
    return value;
  }

  static parse(value) {
    for (const _field in this) {
      if (!Object.hasOwnProperty.call(this, _field)) continue;
      const propertyValue = this[_field];
      if (propertyValue instanceof this && propertyValue.value == value || propertyValue == value) return propertyValue;
    }

    return null;
  }

}
