import { TemplateString } from '@awesome-nodes/object';
import { InjectionProvider } from "./InjectionProvider";
import { InjectionTokenException } from "./InjectionTokenException";
export class InjectionProviderException extends InjectionTokenException {
  get provider() {
    return this._provider;
  }

  constructor(scope, token, _provider, message, messageTemplateValues = {}, innerException) {
    super(scope, token, message, Object.assign(messageTemplateValues, {
      provider: InjectionProvider.toString(_provider)
    }), innerException);
    this._provider = _provider;
    InjectionProviderException.setPrototype(this);
  }

  toString() {
    let toString = super.toString();

    if (this._provider) {
      const provider = InjectionProvider.toString(this._provider);
      if (this._provider && toString.indexOf(provider) == -1) toString += `\nInjection Provider: ${provider}`;
    }

    return toString;
  }

}
export class UnknownInjectionProviderException extends InjectionProviderException {
  constructor(scope, token, message, messageTemplateValues) {
    super(scope, token, undefined, TemplateString`The injection provider for token '${'token'}' could not be located within scope '${'scope'}' and its parents.`.prepend(message), messageTemplateValues);
    UnknownInjectionProviderException.setPrototype(this);
  }

}
export class UnknownInjectionProviderScopeException extends InjectionProviderException {
  constructor(scope, token, provider, message, messageTemplateValues) {
    super(scope, token, provider, TemplateString`The injection scope '${'scope'}' of provider '${'provider'}' for token '${'token'}' does not exist.`.prepend(message), messageTemplateValues);
    UnknownInjectionProviderScopeException.setPrototype(this);
  }

}
export class UnknownInjectionProviderTokenException extends InjectionProviderException {
  constructor(scope, token, provider, message, messageTemplateValues) {
    super(scope, token, provider, TemplateString`The injection token '${'token'}' of provider '${'provider'}' could not be located within scope '${'scope'}' and its parents.`.prepend(message), messageTemplateValues);
    UnknownInjectionProviderTokenException.setPrototype(this);
  }

}
export class DuplicateInjectionProviderException extends InjectionProviderException {
  constructor(scope, token, provider, message, messageTemplateValues) {
    super(scope, token, provider, TemplateString`The injection token '${'token'}' has already a provider '${'provider'}' within scope '${'scope'}' registered.`.prepend(message), messageTemplateValues);
    DuplicateInjectionProviderException.setPrototype(this);
  }

}
